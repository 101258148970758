import { combineReducers } from 'redux';
import globalReducer from './slices/globalSlice';
import { api } from '../services/api';

const rootReducer = combineReducers({
    global: globalReducer,
    api: api.reducer,
});

export default rootReducer;
