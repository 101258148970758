import React, { useState, useCallback } from "react";
import { Box, TextField, Button, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../../services/api";
import { login, setId, setUsername, setCompany, setRole, setPermissions, setAssignedCompanies } from "../../redux/slices/globalSlice";
import backgroundIMG from "../../assets/background.jpg";
import logo from "../../assets/logo-dark.png";

const Login = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [inputs, setInputs] = useState({ email: "", password: "" });
    const [loginMutation] = useLoginMutation();

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    }, []);

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            try {
                const response = await loginMutation(inputs).unwrap();
                const { user } = response;

                if (user) {
                    dispatch(login());
                    dispatch(setId(user._id));
                    dispatch(setUsername(user.name));
                    dispatch(setCompany(user.company));
                    dispatch(setRole(user.role));
                    dispatch(setPermissions(user.permissions));
                    dispatch(setAssignedCompanies(user.assignedCompanies));

                    if (user.role === "Super Admin") {
                        navigate("/homepage");
                    } else {
                        navigate("/dashboard");
                    }
                }
            } catch (err) {
                if (err?.status === 401) {
                    setError("Invalid email or password");
                } else if (err?.status === 404) {
                    setError("User not found");
                } else {
                    setError("An error occurred while logging in. Please try again later.");
                }
            }
        },
        [dispatch, inputs, loginMutation, navigate]
    );

    return (
        <Box
            style={{
                backgroundImage: `url(${backgroundIMG})`,
                backgroundSize: "cover",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {/* Login Box */}
                <Box
                    sx={{
                        width: isMobile ? "90%" : "400px",
                        display: "flex",
                        borderRadius: "10px",
                        backdropFilter: "blur(45px)",
                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                        color: "#ff5e14",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: isMobile ? "20px" : "40px",
                    }}
                >
                    {/* Logo */}
                    <Box style={{ marginBottom: "20px", textAlign: "center" }}>
                        <img src={logo} alt="Logo" style={{ width: isMobile ? "150px" : "250px", height: "auto" }} />
                    </Box>

                    {/* Login Form */}
                    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                        <TextField
                            type="email"
                            placeholder="Email"
                            name="email"
                            onChange={handleChange}
                            value={inputs.email}
                            required
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            sx={{
                                backgroundColor: "#edf5f3",
                                borderRadius: "10px",
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": { border: "none" },
                                    "&:hover fieldset": { border: "none" },
                                    "&.Mui-focused fieldset": { border: "none" },
                                },
                            }}
                        />
                        <TextField
                            type="password"
                            placeholder="Password"
                            name="password"
                            onChange={handleChange}
                            value={inputs.password}
                            required
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            sx={{
                                backgroundColor: "#edf5f3",
                                borderRadius: "10px",
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": { border: "none" },
                                    "&:hover fieldset": { border: "none" },
                                    "&.Mui-focused fieldset": { border: "none" },
                                },
                            }}
                        />
                        {error && (
                            <Box
                                sx={{
                                    width: "100%",
                                    padding: "15px",
                                    margin: "5px 0",
                                    fontSize: "14px",
                                    backgroundColor: "#f34646",
                                    color: "white",
                                    borderRadius: "5px",
                                    textAlign: "center",
                                }}
                            >
                                {error}
                            </Box>
                        )}
                        <Box marginTop="20px">
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ backgroundColor: "#ff5e14", "&:hover": { backgroundColor: "#d65f0f" } }}
                                fullWidth
                            >
                                Sign In
                            </Button>
                        </Box>
                    </form>

                    {/* Copyrights */}
                    <Box style={{ marginTop: "20px", textAlign: "center", borderRadius: "10px" }}>
                        <Typography variant="h6" style={{ margin: "10px", textAlign: "center", color: "white" }}>
                            © {new Date().getFullYear()} JTRACK. All Rights Reserved
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Login;