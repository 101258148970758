import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { useGetSitesQuery } from '../../services/api';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading';

const MapComponent = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const { company } = useSelector((state) => state.global);

    // Fetch active sites
    const { data: sitesData, isLoading } = useGetSitesQuery(company);
    const activeSites = sitesData?.filter(site => site.enabled) || [];

    const qatarCenter = { lat: 25.249469, lng: 51.472279 };

    // Circle marker using a fallback SVG path
    const circleMarker = {
        path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
        fillColor: 'red',
        fillOpacity: 1,
        strokeColor: 'white',
        strokeWeight: 1,
        scale: 2,
    };

    return (
        <Box
            sx={{
                gridColumn: isSmallScreen ? "span 12" : isTablet ? "span 6" : "span 4",
                gridRow: isSmallScreen ? "span 3" : isTablet ? "span 4" : "span 5",
                backgroundColor: theme.palette.background.paper,
                borderRadius: "0.55rem",
                overflow: "hidden",
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                position: 'relative', // Required for absolute positioning of the Loading component
            }}
        >
            {/* Show Loading component while data is being fetched */}
            {isLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                        zIndex: 1, // Ensure it appears above the map
                    }}
                >
                    <Loading />
                </Box>
            )}

            <Box sx={{ height: "100%", width: "100%" }}>
                <APIProvider apiKey="AIzaSyAHJh02Ky1BfcvzBWPOew7ZLytA-1HY1Hc">
                    <Map
                        style={{ width: '100%', height: '100%' }}
                        defaultCenter={qatarCenter}
                        defaultZoom={10}
                        mapId="MAP_ID"
                        gestureHandling="greedy"
                        disableDefaultUI={true}
                    >
                        {/* Render markers for active sites */}
                        {!isLoading && activeSites.map((site) => (
                            <Marker
                                key={site._id}
                                position={{ lat: parseFloat(site.latitude), lng: parseFloat(site.longitude) }}
                                title={site.name}
                                options={{
                                    icon: circleMarker,
                                }}
                            />
                        ))}
                    </Map>
                </APIProvider>
            </Box>
        </Box>
    );
};

export default MapComponent;