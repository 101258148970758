import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import Loading from '../../components/Loading';
import { CUSTOM_COLORS } from '../components/gradientDefs';

const TopDayPieChart = ({ data, isLoading, selectedSensor, selectedYear, selectedMonth, title }) => {
    const themeMode = useSelector(({ global }) => global.mode);

    // Shuffle colors for pie chart segments
    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    // Find the selected sensor data for the given month and year
    const selectedDateKey = `${selectedYear}-${selectedMonth?.toString().padStart(2, '0')}`;
    const selectedSensorData = useMemo(() => {
        return data?.find(entry => entry.Date.startsWith(selectedDateKey));
    }, [data, selectedDateKey]);

    // Filter out unnecessary keys (Date and Total)
    const filteredKeys = useMemo(() => {
        if (!selectedSensorData) return [];
        return Object.keys(selectedSensorData).filter(key => key !== "Date" && key !== "Total");
    }, [selectedSensorData]);

    // Prepare pie chart data
    const pieData = useMemo(() => {
        if (!selectedSensorData) return [];
        return filteredKeys.map(key => ({
            name: key,
            value: selectedSensorData[key],
        }));
    }, [selectedSensorData, filteredKeys]);

    // Assign unique colors to pie chart segments
    const uniqueColors = useMemo(() => {
        const shuffledColors = shuffleArray([...CUSTOM_COLORS]);
        return pieData.reduce((acc, entry, index) => {
            acc[entry.name] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});
    }, [pieData]);

    if (isLoading) {
        return <Loading />;
    }

    if (!data || !selectedMonth || !selectedYear || !selectedSensor || !selectedSensorData) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    return (
        <>
            <ResponsiveContainer width="100%" height="90%">
                <PieChart>
                    <Pie
                        data={pieData}
                        cx="50%"
                        cy="50%"
                        innerRadius="40%"
                        outerRadius="80%"
                        paddingAngle={5}
                        dataKey="value"
                        labelLine={false}
                        stroke="none"
                    >
                        {pieData.map((entry, index) => (
                            <Cell
                                key={`cell-${entry.name}`}
                                fill={uniqueColors[entry.name]}
                            />
                        ))}
                    </Pie>
                    <Tooltip
                        contentStyle={{
                            backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#333333',
                            border: 'none',
                            borderRadius: '8px',
                            color: textColor,
                        }}
                        formatter={(value, name) => {
                            const total = pieData.reduce((acc, entry) => acc + entry.value, 0);
                            const percentage = ((value / total) * 100).toFixed(2);
                            return [`${value} (${percentage}%)`, name];
                        }}
                    />
                </PieChart>
            </ResponsiveContainer>
            <h6 style={{ color: textColor, textAlign: 'center' }}>{`${title} - ${selectedSensorData.Date}`}</h6>
        </>
    );
};

export default TopDayPieChart;