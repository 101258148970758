import React from 'react'
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import DashboardTotalPie from '../../charts/dashboardCharts/DashboardTotalPie'

const TotalCountPie = ({ data, isLoading }) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

    return (
        <Box
            gridColumn={isSmallScreen ? "span 12" : isTablet ? "span 6" : "span 2"}
            gridRow="span 2"
            backgroundColor={theme.palette.background.paper}
            borderRadius="0.55rem"
            sx={{
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography variant="h3" sx={{ color: theme.palette.text.white, mt: 2, ml: 2, fontWeight: "bold"}}>
                TOTAL
            </Typography>
            <Box
                sx={{
                    height: "calc(100% - 65px)",
                    width: "100%",
                    position: "relative",
                }}
            >
                <DashboardTotalPie data={data} isLoading={isLoading} />
            </Box>
        </Box>
    )
}

export default TotalCountPie