import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import DashboardYearlyChart from '../../charts/dashboardCharts/DashboardYearlyChart';

const YearlyChart = ({ data, isLoading }) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            gridColumn={isSmallScreen ? "span 12" : "span 6"}
            gridRow="span 2"
            backgroundColor={theme.palette.background.paper}
            borderRadius="0.55rem"
            postion="relative"
            sx={{
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography variant='h3' sx={{ color: theme.palette.text.white, mt: "2%", ml: "2%", fontWeight: "bold"}}>
                YEARLY
            </Typography>
            <Box style={{ height: "100%", width : "100%"}} postion="absolute">
                <DashboardYearlyChart processedData={data} isLoading={isLoading} />
            </Box>
        </Box>
    )
}

export default YearlyChart