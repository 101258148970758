import React from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography, Box, useTheme } from '@mui/material';

const TopWeekTable = ({ title, data, selectedSensor, selectedYear, selectedMonth }) => {
    const theme = useTheme();

    // Extract data object from array
    const dataObject = (data && data[0]) || {};

    const renderDataRows = () => {
        const rows = [];
        const monthData = dataObject;

        for (const key in monthData) {
            if (key !== 'week' && key !== 'Total') {
                rows.push(
                    <TableRow key={key}>
                        <TableCell align="center">{key}</TableCell>
                        <TableCell align="center">{monthData[key]}</TableCell>
                        <TableCell align="center">{monthData.week}</TableCell>
                    </TableRow>
                );
            }
        }
        return rows;
    };

    return (
        <Box
            backgroundColor={theme.palette.background.paper}
            borderRadius="15px"
            display="flex"
            flexDirection="column"
            position="relative"
            boxShadow='8px 8px 20px rgba(0, 0, 0, 0.1)'
        >
            <Typography variant="h3" sx={{ color: theme.palette.text.white, m: "2%", p: "2%", fontWeight: 'bold', textAlign: "center" }}>{title}</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Logic Name</TableCell>
                            <TableCell align="center">Count</TableCell>
                            <TableCell align="center">Week</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderDataRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default TopWeekTable;
