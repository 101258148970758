import React from 'react';
import { Box, useTheme } from '@mui/material';
import DailyBarChart from '../../charts/barCharts/DailyChart';
import DailyLineChart from '../../charts/lineCharts/DailyChart';
import DailyPieChart from '../../charts/pieCharts/DailyChart';
import Loading from '../../components/Loading';

const DailyContent = ({ selectedSensor, selectedDateRange, showLabels, processedData, chartIsLoading, error, isSmallScreen }) => {

    const theme = useTheme();

    if (!selectedSensor) {
        return <Loading />;
    }

    return (
        <Box display="flex" flexDirection="column" width="100%">
            <Box
                height="65vh"
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: isSmallScreen ? '1rem' : '0rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <DailyBarChart 
                    selectedMac={selectedSensor} 
                    selectedDateRange={selectedDateRange}
                    showLabels={showLabels}
                    isSmallScreen={isSmallScreen}
                    processedData={processedData}
                    chartIsLoading={chartIsLoading}
                    error={error}
                />
            </Box>
            <Box
                height="65vh"
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: '1rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <DailyLineChart 
                    selectedMac={selectedSensor} 
                    selectedDateRange={selectedDateRange}
                    showLabels={showLabels}
                    isSmallScreen={isSmallScreen}
                    processedData={processedData}
                    chartIsLoading={chartIsLoading}
                    error={error}
                />
            </Box>
            <Box
                height={isSmallScreen ? "50vh" : "65vh"}
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: '1rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <DailyPieChart
                    selectedMac={selectedSensor}
                    selectedDateRange={selectedDateRange}
                    processedData={processedData}
                    chartIsLoading={chartIsLoading}
                    error={error}
                    isSmallScreen={isSmallScreen}
                />
            </Box>
        </Box>
    );
};

export default DailyContent;
