import React, { useState, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import { useTheme, useMediaQuery } from '@mui/material';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { CUSTOM_COLORS } from '../components/gradientDefs';

const DailyLineChart = ({ selectedMac, selectedDateRange, processedData, chartIsLoading, error, showLabels }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [activeLegends, setActiveLegends] = useState([]);
    const themeMode = useSelector(({ global }) => global.mode);

    const shuffleColors = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    // Process data and assign unique colors
    const { dailyData, allLineNames, chartData, uniqueColors } = useMemo(() => {
        const data = processedData && processedData[selectedMac] ? processedData[selectedMac] : null;

        const lineNames = data ? Array.from(new Set(Object.values(data).flatMap(dailyCounts => Object.keys(dailyCounts)))) : [];
        const sortedLineNames = lineNames.sort((a, b) => {
            const aNum = parseFloat(a);
            const bNum = parseFloat(b);
            if (!isNaN(aNum) && !isNaN(bNum)) {
                return aNum - bNum;
            }
            return a.localeCompare(b);
        });

        const dateRange = selectedDateRange[0];
        const allDates = [];
        let currentDate = new Date(dateRange.startDate);
        while (currentDate <= dateRange.endDate) {
            allDates.push(format(currentDate, 'yyyy-MM-dd'));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        const chartData = allDates.map(date => {
            const dateCounts = data?.[date];
            const dayData = { day: format(new Date(date), "do MMM") }; // Include month in the label
            sortedLineNames.forEach(name => dayData[name] = dateCounts?.[name] || 0);
            return dayData;
        });

        const shuffledColors = shuffleColors([...CUSTOM_COLORS]);
        const uniqueColors = sortedLineNames.reduce((acc, lineName, index) => {
            acc[lineName] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});

        return {
            dailyData: data,
            allLineNames: sortedLineNames,
            chartData,
            uniqueColors,
        };
    }, [processedData, selectedMac, selectedDateRange]);

    // Calculate dynamic interval for XAxis ticks based on the number of dates
    const calculateInterval = () => {
        const dataLength = chartData.length;
        if (dataLength <= 7) return 0; // Show all ticks for a week or less
        if (dataLength <= 14) return 1; // Show every other tick for up to 2 weeks
        if (dataLength <= 30) return 2; // Show every third tick for up to a month
        return Math.floor(dataLength / 10); // For longer ranges, show 10 ticks
    };

    const interval = calculateInterval();

    if (chartIsLoading) {
        return <Loading />;
    }

    if (error || error?.status === 404 || !dailyData || Object.keys(dailyData).length === 0) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const handleLegendClick = (legendItem) => {
        setActiveLegends((prevActiveLegends) =>
            prevActiveLegends.includes(legendItem.dataKey)
                ? prevActiveLegends.filter((item) => item !== legendItem.dataKey)
                : [...prevActiveLegends, legendItem.dataKey]
        );
    };

    const hiddenLines = activeLegends.reduce((acc, key) => {
        acc[key] = true;
        return acc;
    }, {});

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={chartData}
                margin={isSmallScreen ? { top: 30, right: 20, bottom: 20, left: 10 } : { top: 20, right: 30, bottom: 10, left: 10 }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis
                    dataKey="day"
                    angle={isSmallScreen ? -50 : 0}
                    textAnchor={isSmallScreen ? 'end' : 'middle'}
                    interval={interval}
                    tick={{ fill: textColor, fontSize: isSmallScreen ? 10 : 12 }}
                />
                <YAxis
                    tick={{ fill: textColor }}
                    width={30}
                />
                <Tooltip
                    contentStyle={{
                        backgroundColor: theme.palette.background.default,
                        border: 'none',
                        borderRadius: '8px',
                        color: theme.palette.text.primary,
                    }}
                />
                <Legend
                    align="center"
                    onClick={handleLegendClick}
                    wrapperStyle={{
                        paddingTop: isSmallScreen ? 30 : 20,
                    }}
                    formatter={(value) => (
                        <span
                            style={{
                                color: hiddenLines[value] ? theme.palette.text.disabled : theme.palette.text.primary,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                />
                {allLineNames.map((lineId) => (
                    <Line
                        key={lineId}
                        type="monotone"
                        dataKey={lineId}
                        strokeWidth={2}
                        dot={true}
                        name={lineId}
                        hide={hiddenLines[lineId]}
                        stroke={uniqueColors[lineId]}
                    >
                        {showLabels && (
                            <LabelList
                                dataKey={lineId}
                                position="top"
                                fill={textColor}
                                fontSize={isSmallScreen ? 10 : 12}
                                formatter={(value) => (value > 0 ? value.toLocaleString() : '')}
                            />
                        )}
                    </Line>
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default DailyLineChart;