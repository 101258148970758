import React, { useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { CUSTOM_COLORS } from '../components/gradientDefs';
import { useTheme, useMediaQuery } from '@mui/material';

const HourlyBarChart = ({ selectedMac, showLabels, processedData, chartIsLoading, error }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const [disabledBars, setDisabledBars] = useState({});
    const themeMode = useSelector(({ global }) => global.mode);

    const hourlyData = useMemo(() => {
        if (processedData && processedData[selectedMac]) {
            return processedData[selectedMac];
        }
        return null;
    }, [processedData, selectedMac]);

    const allBarNames = useMemo(() => {
        if (hourlyData) {
            const barNames = Array.from(new Set(Object.values(hourlyData).flatMap(hourlyCounts => Object.keys(hourlyCounts))));
            return barNames.sort((a, b) => {
                const aNum = parseFloat(a);
                const bNum = parseFloat(b);
                if (!isNaN(aNum) && !isNaN(bNum)) {
                    return aNum - bNum;
                }
                return a.localeCompare(b);
            });
        }
        return [];
    }, [hourlyData]);

    const chartData = useMemo(() => {
        const data = Array.from({ length: 24 }, (_, hour) => {
            const hourData = { hour: `${hour}:00` };
            allBarNames.forEach(name => hourData[name] = 0);
            return hourData;
        });

        if (hourlyData) {
            Object.entries(hourlyData).forEach(([hour, counts]) => {
                const index = parseInt(hour);
                if (!isNaN(index) && index >= 0 && index < 24) {
                    Object.entries(counts).forEach(([logic, count]) => {
                        data[index][logic] = count;
                    });
                }
            });
        }

        return data;
    }, [hourlyData, allBarNames]);

    const shuffleColors = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const uniqueColors = useMemo(() => {
        const shuffledColors = shuffleColors([...CUSTOM_COLORS]);
        return allBarNames.reduce((acc, barName, index) => {
            acc[barName] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});
    }, [allBarNames]);

    if (chartIsLoading) {
        return <Loading />;
    }

    if (error || error?.status === 404 || !hourlyData || Object.keys(hourlyData).length === 0) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const handleLegendClick = (legendItem) => {
        const clickedBar = legendItem.dataKey;
        setDisabledBars((prev) => ({
            ...prev,
            [clickedBar]: !prev[clickedBar],
        }));
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={chartData}
                margin={isSmallScreen ? { top: 30, right: 20, bottom: 20, left: 10 } : { top: 20, right: 30, bottom: 10, left: 10 }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis
                    dataKey="hour"
                    angle={isSmallScreen ? -50 : 0}
                    textAnchor={isSmallScreen ? 'end' : 'middle'}
                    interval={isSmallScreen ? 3 : isTablet ? 2 : 0}
                    tick={{ fill: textColor, fontSize: isSmallScreen ? 10 : 12 }}
                    tickFormatter={(value) => {
                        const [hour] = value.split(':');
                        return `${hour}:00`;
                    }}
                />
                <YAxis
                    tick={{ fill: textColor }}
                    width={30}
                />
                <Tooltip
                    contentStyle={{
                        backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#333333',
                        border: 'none',
                        borderRadius: '8px',
                        color: textColor,
                    }}
                    cursor={{ fill: 'grey', opacity: 0.1 }}
                />
                <Legend
                    align="center"
                    wrapperStyle={{ color: textColor, paddingTop: isSmallScreen ? 30 : 20 }}
                    onClick={handleLegendClick}
                    formatter={(value) => (
                        <span
                            style={{
                                color: disabledBars[value] ? '#888' : textColor,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                />
                {allBarNames.map((key) => (
                    <Bar
                        key={key}
                        dataKey={key}
                        fill={uniqueColors[key]}
                        name={key}
                        hide={disabledBars[key]}
                    >
                        {showLabels && (
                            <LabelList
                                dataKey={key}
                                position="top"
                                fill={textColor}
                                fontSize={isSmallScreen ? 10 : 12}
                                formatter={(value) => (value > 0 ? value.toLocaleString() : '')}
                            />
                        )}
                    </Bar>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default HourlyBarChart;