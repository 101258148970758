import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import Loading from '../../components/Loading';
import { CUSTOM_COLORS } from '../components/gradientDefs';

const TopWeekPieChart = ({ data, isLoading, selectedSensor, title }) => {
    const themeMode = useSelector(({ global }) => global.mode);

    const getOrdinalSuffix = (week) => {
        if (week > 3 && week < 21) return 'th';
        switch (week % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    };

    // Format the week number with ordinal suffix
    const formatWeek = (week) => {
        if (week === undefined || week === null) return 'N/A';

        let weekNumber;
        if (typeof week === 'string') {
            weekNumber = parseInt(week.replace('Week ', ''), 10);
        } else if (typeof week === 'number') {
            weekNumber = week;
        } else {
            return 'N/A';
        }

        if (isNaN(weekNumber)) return 'N/A';
        return `${weekNumber}${getOrdinalSuffix(weekNumber)} week`;
    };

    // Shuffle colors for pie chart segments
    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    // Check for data availability
    const dataObject = useMemo(() => {
        if (!data || !Array.isArray(data) || data.length === 0) return null;
        return data[0];
    }, [data]);

    // Prepare pie chart data
    const pieData = useMemo(() => {
        if (!dataObject) return [];
        const filteredKeys = Object.keys(dataObject).filter(key => key !== 'Total' && key !== 'week');
        return filteredKeys.map(key => ({
            name: key,
            value: dataObject[key],
        }));
    }, [dataObject]);

    // Assign unique colors to pie chart segments
    const uniqueColors = useMemo(() => {
        const shuffledColors = shuffleArray([...CUSTOM_COLORS]);
        return pieData.reduce((acc, entry, index) => {
            acc[entry.name] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});
    }, [pieData]);

    if (isLoading) {
        return <Loading />;
    }

    if (!data || !selectedSensor || !dataObject || Object.keys(dataObject).length === 0) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    return (
        <>
            <ResponsiveContainer width="100%" height="90%">
                <PieChart>
                    <Pie
                        data={pieData}
                        cx="50%"
                        cy="50%"
                        innerRadius="40%"
                        outerRadius="80%"
                        paddingAngle={5}
                        dataKey="value"
                        labelLine={false}
                        stroke="none"
                    >
                        {pieData.map((entry, index) => (
                            <Cell
                                key={`cell-${entry.name}`}
                                fill={uniqueColors[entry.name]}
                            />
                        ))}
                    </Pie>
                    <Tooltip
                        contentStyle={{
                            backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#333333',
                            border: 'none',
                            borderRadius: '8px',
                            color: textColor,
                        }}
                        formatter={(value, name) => {
                            const total = pieData.reduce((acc, entry) => acc + entry.value, 0);
                            const percentage = ((value / total) * 100).toFixed(2);
                            return [`${value} (${percentage}%)`, name];
                        }}
                    />
                </PieChart>
            </ResponsiveContainer>
            <h6 style={{ color: textColor, textAlign: 'center' }}>
                {`${title} - ${formatWeek(data[0]?.week)}`}
            </h6>
        </>
    );
};

export default TopWeekPieChart;