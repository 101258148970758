import React from 'react';
import { Box, Button, MenuItem, Select, TextField, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import mainLocationsInQatar from '../../constants/mainLocations';

const SiteDetailsForm = ({ site, siteDetails, handleInputChange, handleSiteSelect, handleUpdateSiteDetails, filteredSites, isSmallScreen }) => {
    const theme = useTheme();

    return (
        <Box
            p={4}
            borderRadius={5}
            sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.secondary.main,
                width: '100%',
                mt: 2,
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography variant="h3" sx={{ fontSize: '2rem', fontWeight: 'bold', color: theme.palette.text.white, mb: '1rem' }}>
                Site Details
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{ color: theme.palette.text.white }}>Select Site</Typography>
                    <Select
                        fullWidth
                        value={site}
                        onChange={handleSiteSelect}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Select Site
                        </MenuItem>
                        {filteredSites.map((site) => (
                            <MenuItem key={site._id} value={site.name}>
                                {site.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Change Site Name</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={siteDetails.name}
                        onChange={(e) => handleInputChange(e, 'name')}
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Location</Typography>
                    <Select
                        fullWidth
                        value={siteDetails.location}
                        onChange={(e) => handleInputChange(e, 'location')}
                    >
                        {mainLocationsInQatar && mainLocationsInQatar.map((location) => (
                            <MenuItem key={location.name} value={location.name}>
                                {location.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Latitude</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={siteDetails.latitude}
                        onChange={(e) => handleInputChange(e, 'latitude')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Longitude</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={siteDetails.longitude}
                        onChange={(e) => handleInputChange(e, 'longitude')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        gap={3}
                        mt={2}
                    >
                        <Button
                            onClick={handleUpdateSiteDetails}
                            variant="contained"
                            sx={{
                                backgroundColor: theme.palette.text.white,
                                color: theme.palette.text.black,
                                padding: "0.5rem 1rem",
                                '&:hover': {
                                    backgroundColor: theme.palette.text.white,
                                },
                                '&:focus': {
                                    outline: 'none',
                                },
                                m: '0.5rem'
                            }}
                        >
                            Update Site Details
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SiteDetailsForm;