import React from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, useTheme } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import Loading from '../../components/Loading';
import { styled } from '@mui/system';

const StyledTableContainer = styled(TableContainer)`
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    & {
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
`;

const WeeklyCountTable = ({ title, isLoading, data, selectedSensor, selectedMonth, selectedYear }) => {
    const theme = useTheme();

    if (isLoading) {
        return <Loading />;
    }

    if (!data || !selectedSensor || !selectedMonth || !selectedYear) {
        return <NoDataAvailableMessage />;
    }

    // Get all unique keys from all weeks, ensuring "Total" is the last column before "Comparison"
    const getAllUniqueKeys = () => {
        const weeksData = data[selectedSensor]?.[selectedYear]?.[selectedMonth] || {};
        const uniqueKeys = new Set();

        for (const weekNumber in weeksData) {
            const weekData = weeksData[weekNumber];
            Object.keys(weekData).forEach((key) => {
                if (key !== 'Total') {
                    uniqueKeys.add(key);
                }
            });
        }

        // Convert to array and add "Total" at the end
        const keysArray = Array.from(uniqueKeys);
        keysArray.push('Total'); // Ensure "Total" is the last column
        return keysArray;
    };

    const renderDataRows = () => {
        const rows = [];
        const weeksData = data[selectedSensor]?.[selectedYear]?.[selectedMonth] || {};
        const uniqueKeys = getAllUniqueKeys();

        let previousTotal = 0;
        for (const weekNumber in weeksData) {
            const weekData = weeksData[weekNumber];
            const currentTotal = weekData.Total || 0; // Use the "Total" key if available

            const comparisonIcon = previousTotal > currentTotal ? <ArrowDownward color="error" /> : <ArrowUpward color="success" />;
            previousTotal = currentTotal;

            rows.push(
                <TableRow key={`${selectedSensor}-${selectedYear}-${selectedMonth}-${weekNumber}`}>
                    <TableCell align="center">{`Week ${weekNumber}`}</TableCell>
                    {uniqueKeys.map((key) => (
                        <TableCell align="center" key={key}>{weekData[key] || 0}</TableCell>
                    ))}
                    <TableCell align="center">{comparisonIcon}</TableCell>
                </TableRow>
            );
        }
        return rows;
    };

    const renderTableHeader = () => {
        const uniqueKeys = getAllUniqueKeys();
        return (
            <TableRow>
                <TableCell align="center">Week No</TableCell>
                {uniqueKeys.map((key) => (
                    <TableCell align="center" key={key}>{key}</TableCell>
                ))}
                <TableCell align="center">Comparison</TableCell>
            </TableRow>
        );
    };

    return (
        <Box
            backgroundColor={theme.palette.background.paper}
            borderRadius="15px"
            display="flex"
            flexDirection="column"
            position="relative"
            boxShadow='8px 8px 20px rgba(0, 0, 0, 0.1)'
        >
            <Typography variant="h3" sx={{ color: theme.palette.text.white, m: "2%", p: "2%", fontWeight: 'bold', textAlign: "center" }}>{title}</Typography>
            <StyledTableContainer component={Paper}>
                <Table>
                    <TableHead>
                        {renderTableHeader()}
                    </TableHead>
                    <TableBody>
                        {renderDataRows()}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </Box>
    );
};

export default WeeklyCountTable;