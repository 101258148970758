import React, { useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { getISOWeek, getISOWeekYear } from 'date-fns'; // Use getISOWeekYear instead of getISOYear
import { CUSTOM_COLORS } from '../components/gradientDefs';
import { useTheme, useMediaQuery } from '@mui/material';

const WeeklyBarChart = ({ selectedMac, selectedMonth, selectedYear, showLabels, processedData, isLoading, error }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [disabledBars, setDisabledBars] = useState({});
    const themeMode = useSelector(({ global }) => global.mode);

    // Function to shuffle the CUSTOM_COLORS array
    const shuffleColors = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    // Process data and assign unique colors
    const { chartData, allBarNames, uniqueColors } = useMemo(() => {
        if (!processedData || isLoading) return { chartData: [], allBarNames: [], uniqueColors: {} };

        const startDate = new Date(selectedYear, selectedMonth - 1, 1);
        const endDate = new Date(selectedYear, selectedMonth, 0);

        const startISOWeek = getISOWeek(startDate);
        const startISOYear = getISOWeekYear(startDate); // Use getISOWeekYear
        const endISOWeek = getISOWeek(endDate);
        const endISOYear = getISOWeekYear(endDate); // Use getISOWeekYear

        const yearData = processedData[selectedMac]?.[selectedYear];
        if (!yearData) return { chartData: [], allBarNames: [], uniqueColors: {} };

        const data = [];
        let currentWeek = startISOWeek;
        let currentYear = startISOYear;
        let firstValidWeekFound = false;

        while (currentYear < endISOYear || (currentYear === endISOYear && currentWeek <= endISOWeek)) {
            const weekData = yearData[selectedMonth]?.[currentWeek];
            if (weekData) {
                const { Total, ...filteredWeekData } = weekData;

                if (Total > 0) {
                    firstValidWeekFound = true;
                    data.push({ week: currentWeek, ...filteredWeekData });
                } else if (firstValidWeekFound) {
                    data.push({ week: currentWeek, ...filteredWeekData });
                }
            }

            // Move to the next week
            currentWeek++;
            if (currentWeek > 52) {
                currentWeek = 1;
                currentYear++;
            }
        }

        const barNames = data.length > 0 ? Object.keys(data[0]).filter(key => key !== 'week') : [];
        const sortedBarNames = barNames.sort((a, b) => {
            const aNum = parseFloat(a);
            const bNum = parseFloat(b);
            if (!isNaN(aNum) && !isNaN(bNum)) {
                return aNum - bNum;
            }
            return a.localeCompare(b);
        });

        const shuffledColors = shuffleColors([...CUSTOM_COLORS]);
        const uniqueColors = sortedBarNames.reduce((acc, barName, index) => {
            acc[barName] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});

        return {
            chartData: data,
            allBarNames: sortedBarNames,
            uniqueColors,
        };
    }, [processedData, isLoading, selectedMac, selectedYear, selectedMonth]);

    if (isLoading) {
        return <Loading />;
    }

    if (error || error?.status === 404 || !processedData || Object.keys(processedData).length === 0 || !chartData.length) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const handleLegendClick = (legendItem) => {
        const clickedBar = legendItem.dataKey;
        setDisabledBars((prev) => ({
            ...prev,
            [clickedBar]: !prev[clickedBar],
        }));
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={chartData}
                margin={isSmallScreen ? { top: 30, right: 20, bottom: 20, left: 20 } : { top: 20, right: 30, bottom: 10, left: 20 }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis
                    dataKey="week"
                    textAnchor='middle'
                    tick={{ fill: textColor, fontSize: isSmallScreen ? 10 : 12 }}
                    tickFormatter={(value) => `Week ${value}`}
                />
                <YAxis
                    tick={{ fill: textColor }}
                    width={30}
                />
                <Tooltip
                    contentStyle={{
                        backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#333333',
                        border: 'none',
                        borderRadius: '8px',
                        color: textColor,
                    }}
                    cursor={{ fill: 'grey', opacity: 0.1 }}
                />
                <Legend
                    align="center"
                    wrapperStyle={{ color: textColor, paddingTop: isSmallScreen ? 30 : 20 }}
                    onClick={handleLegendClick}
                    formatter={(value) => (
                        <span
                            style={{
                                color: disabledBars[value] ? '#888' : textColor,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                />
                {allBarNames.map((key) => (
                    <Bar
                        key={key}
                        dataKey={key}
                        fill={uniqueColors[key]}
                        name={key}
                        hide={disabledBars[key]}
                    >
                        {showLabels && (
                            <LabelList
                                dataKey={key}
                                position="top"
                                fill={textColor}
                                fontSize={isSmallScreen ? 10 : 12}
                                formatter={(value) => (value > 0 ? value.toLocaleString() : '')}
                            />
                        )}
                    </Bar>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default WeeklyBarChart;