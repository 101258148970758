import { linearGradientDef } from '@nivo/core';

export const CUSTOM_COLORS = ['#007DD6', '#07E098', '#FFCF00', '#FA5A7D', '#BF83FF', '#FF947A'];

export const customColorScheme = ['#28A3FE', '#8158F6', '#FC5D2A', '#BD1313', '#56b340', '#e7c070', '#4a57f9', '#c43da6'];

export const gradientDefs = [
    linearGradientDef('gradient1', [
        { offset: 0, color: '#3777D6' },
        { offset: 100, color: '#1F4378' }
    ]),
    linearGradientDef('gradient2', [
        { offset: 0, color:  '#CBE0FF'},
        { offset: 100, color: '#326BBF' }
    ]),
    linearGradientDef('gradient3', [
        { offset: 0, color: '#FC8F2A' },
        { offset: 100, color: '#FB2A29' }
    ]),
    linearGradientDef('gradient4', [
        { offset: 0, color: '#fc1919' },
        { offset: 100, color: '#7e0c0c' }
    ]),
    linearGradientDef('gradient5', [
        { offset: 0, color: '#7ed957' },
        { offset: 100, color: '#2d8c28' }
    ]),
    linearGradientDef('gradient6', [
        { offset: 0, color: '#fddb92' },
        { offset: 100, color: '#d1a54d' }
    ]),
    linearGradientDef('gradient7', [
        { offset: 0, color: '#6a73f7' },
        { offset: 100, color: '#2a3afb' }
    ]),
    linearGradientDef('gradient8', [
        { offset: 0, color: '#f753c6' },
        { offset: 100, color: '#912785' }
    ])
];
