import React from 'react';
import { Box, useTheme } from '@mui/material';
import Header from '../../components/Header';

const HourlyHeader = ({ isSmallScreen, children }) => {

    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            justifyContent="space-between"
            marginBottom="1rem"
            padding="1rem"
            backgroundColor={theme.palette.background.paper}
            borderRadius='15px'
            sx={{
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Header title="HOURLY" subtitle="View your hourly counts of your devices" />
            <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} alignItems="center" gap={1}>
                {children}
            </Box>
        </Box>
    );
};

export default HourlyHeader;
