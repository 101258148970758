import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { CUSTOM_COLORS } from '../components/gradientDefs';

const YearlyPieChart = ({ selectedMac, processedData, chartIsLoading, error }) => {
    const themeMode = useSelector(({ global }) => global.mode);
    const [disabledLegends, setDisabledLegends] = useState([]);

    // Function to shuffle the CUSTOM_COLORS array
    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    // Process data and assign unique colors
    const { pieData, uniqueColors } = useMemo(() => {
        if (!processedData || chartIsLoading) return { pieData: [], uniqueColors: {} };

        const filteredData = selectedMac ? (processedData && processedData[selectedMac]) || {} : processedData || {};

        const lastFiveYears = Array.from({ length: 5 }, (_, index) => {
            const currentYear = new Date().getFullYear();
            return (currentYear - index).toString();
        });

        const aggregatedData = {};
        lastFiveYears.forEach(year => {
            const yearData = filteredData[year] || {};
            Object.entries(yearData).forEach(([category, value]) => {
                if (!aggregatedData[category]) {
                    aggregatedData[category] = 0;
                }
                aggregatedData[category] += value || 0;
            });
        });

        const pieData = Object.entries(aggregatedData).map(([key, value]) => ({
            name: key,
            value,
        }));

        const shuffledColors = shuffleArray([...CUSTOM_COLORS]);
        const uniqueColors = pieData.reduce((acc, entry, index) => {
            acc[entry.name] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});

        return {
            pieData,
            uniqueColors,
        };
    }, [processedData, chartIsLoading, selectedMac]);

    if (chartIsLoading) {
        <Loading />
    }

    if (error || error?.status === 404 || !processedData || Object.keys(processedData).length === 0 || !pieData.length) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const handleLegendClick = (entry) => {
        const clickedLegend = entry.value;
        setDisabledLegends((prev) =>
            prev.includes(clickedLegend)
                ? prev.filter((item) => item !== clickedLegend)
                : [...prev, clickedLegend]
        );
    };

    const filteredData = pieData.filter((entry) => !disabledLegends.includes(entry.name));

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie
                    data={filteredData}
                    cx="50%"
                    cy="50%"
                    innerRadius="40%"
                    outerRadius="80%"
                    paddingAngle={5}
                    dataKey="value"
                    labelLine={false}
                    stroke="none"
                >
                    {pieData.map((entry, index) =>
                        !disabledLegends.includes(entry.name) ? (
                            <Cell
                                key={`cell-${entry.name}`}
                                fill={uniqueColors[entry.name]}
                            />
                        ) : null
                    )}
                </Pie>
                <Tooltip
                    contentStyle={{
                        backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#333333',
                        border: 'none',
                        borderRadius: '8px',
                        color: textColor,
                    }}
                    formatter={(value, name) => {
                        const total = pieData.reduce((acc, entry) => acc + entry.value, 0);
                        const percentage = ((value / total) * 100).toFixed(2);
                        return [`${value} (${percentage}%)`, name];
                    }}
                />
                <Legend
                    wrapperStyle={{ color: textColor }}
                    onClick={handleLegendClick}
                    formatter={(value) => (
                        <span
                            style={{
                                color: disabledLegends.includes(value) ? '#888' : textColor,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                    payload={pieData.map((entry) => ({
                        value: entry.name,
                        type: 'circle',
                        id: entry.name,
                        color: disabledLegends.includes(entry.name)
                            ? '#888'
                            : uniqueColors[entry.name],
                    }))}
                    layout="horizontal"
                    align="center"
                    verticalAlign="bottom"
                />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default YearlyPieChart;