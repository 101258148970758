import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, useTheme, Slide, FormControl, InputLabel, Select, MenuItem, Button, Box } from '@mui/material';
import { useAddSiteMutation } from '../../services/api';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddSiteDialogBox = ({ openDialog, handleCloseDialog, mainLocationsInQatar, refetch, setSnackbar }) => {
    const theme = useTheme();

    const { company } = useSelector((state) => state.global);

    const [siteData, setSiteData] = useState({ name: '', location: '', latitude: '', longitude: '' });

    const [addSite] = useAddSiteMutation();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSiteData({
            ...siteData,
            [name]: value,
        });
    };

    const handleAddSite = async () => {
        try {
            const { name, location, latitude, longitude } = siteData;
            if (name && location && latitude && longitude) {
                await addSite({ ...siteData, company });
                handleCloseDialog();
                refetch();
                setSiteData({ name: '', location: '', latitude: '', longitude: '' });
                setSnackbar({ open: true, message: "Site added successfully", severity: "success" });
            } else {
                setSnackbar({ open: true, message: "Please fill in all the fields", severity: "error" });
            }
        } catch (error) {
            console.error('Error adding site:', error);
        }
    };

    return (
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            onClose={handleCloseDialog}
            aria-describedby="add-device-dialog-description"
            PaperProps={{ sx: { backgroundColor: theme.palette.background.paper } }}
        >
            <DialogTitle variant="h2" fontWeight="bold">
                Add New Site
            </DialogTitle>
            <DialogContent sx={{ width: '500px', padding: '20px' }}>
                <Box display="flex" flexDirection="column" gap={2} sx={{ paddingTop: '8px' }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="siteName"
                        name="name"
                        label="Name of Site"
                        fullWidth
                        value={siteData.name}
                        onChange={handleInputChange}
                        autoComplete="off"
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="location-label">Location</InputLabel>
                        <Select
                            labelId="location-label"
                            id="location"
                            name="location"
                            value={siteData.location}
                            onChange={handleInputChange}
                            label="Location"
                        >
                            {mainLocationsInQatar.map((location) => (
                                <MenuItem key={location.name} value={location.name}>
                                    {location.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        id="latitude"
                        name="latitude"
                        label="Latitude"
                        fullWidth
                        value={siteData.latitude}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        id="longitude"
                        name="longitude"
                        label="Longitude"
                        fullWidth
                        value={siteData.longitude}
                        onChange={handleInputChange}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: '16px 24px' }}>
                <Button
                    onClick={handleCloseDialog}
                    variant="contained"
                    sx={{
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.text.white,
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: theme.palette.text.white,
                        },
                        '&:focus': {
                            outline: 'none',
                        }
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleAddSite}
                    variant="contained"
                    sx={{
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.text.white,
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: theme.palette.text.white,
                        },
                        '&:focus': {
                            outline: 'none',
                        }
                    }}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddSiteDialogBox;