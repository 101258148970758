import React from 'react';
import StatBox from '../../components/StatBox';
import { useTheme, useMediaQuery } from '@mui/material';

const StatBoxes = ({ totalDevicesConnected, totalSites, devicesOffline, totalHumanCount }) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    return (
        <>
            <StatBox
                title="Devices Connected"
                value={totalDevicesConnected.toString()}
                isSmallScreen={isSmallScreen}
                isTablet={isTablet}
                index={0}
            />
            <StatBox
                title="Active Sites"
                value={totalSites.toString()}
                isSmallScreen={isSmallScreen}
                isTablet={isTablet}
                index={1}
            />
            <StatBox
                title="Devices Offline"
                value={devicesOffline.toString()}
                isSmallScreen={isSmallScreen}
                isTablet={isTablet}
                index={2}
            />
            <StatBox
                title="Total Human Count"
                value={totalHumanCount.toString()}
                isSmallScreen={isSmallScreen}
                isTablet={isTablet}
                index={3}
            />
        </>
    )
}

export default StatBoxes