import React, { useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { CUSTOM_COLORS } from '../components/gradientDefs';
import { useTheme, useMediaQuery } from '@mui/material';

const YearlyBarChart = ({ selectedMac, showLabels, processedData, error, chartIsLoading }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [disabledBars, setDisabledBars] = useState({});
    const themeMode = useSelector(({ global }) => global.mode);

    // Function to shuffle the CUSTOM_COLORS array
    const shuffleColors = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    // Process data and assign unique colors
    const { chartData, allBarNames, uniqueColors } = useMemo(() => {
        // Return null if no processedData or loading
        if (!processedData || chartIsLoading) return { chartData: null, allBarNames: null, uniqueColors: null };

        const filteredData = selectedMac ? (processedData && processedData[selectedMac]) || {} : processedData || {};

        const lastFiveYears = Array.from({ length: 5 }, (_, index) => {
            const currentYear = new Date().getFullYear();
            return (currentYear - index).toString();
        });

        const keys = new Set();
        lastFiveYears.forEach(year => {
            const yearData = filteredData[year] || {};
            Object.keys(yearData).forEach(key => keys.add(key));
        });

        // If no keys are found, return null
        if (keys.size === 0) return { chartData: null, allBarNames: null, uniqueColors: null };

        const sortedBarNames = Array.from(keys).sort((a, b) => {
            const aNum = parseFloat(a);
            const bNum = parseFloat(b);
            if (!isNaN(aNum) && !isNaN(bNum)) {
                return aNum - bNum;
            }
            return a.localeCompare(b);
        });

        const chartData = lastFiveYears.reverse().map(year => {
            const yearData = filteredData[year] || {};
            const yearValues = { year };

            sortedBarNames.forEach(category => {
                yearValues[category] = yearData[category] || 0;
            });

            return yearValues;
        });

        // If chartData is empty, return null
        if (chartData.length === 0) return { chartData: null, allBarNames: null, uniqueColors: null };

        const shuffledColors = shuffleColors([...CUSTOM_COLORS]);
        const uniqueColors = sortedBarNames.reduce((acc, barName, index) => {
            acc[barName] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});

        return {
            chartData,
            allBarNames: sortedBarNames,
            uniqueColors,
        };
    }, [processedData, chartIsLoading, selectedMac]);

    if (chartIsLoading) {
        return <Loading />;
    }

    if (error || error?.status === 404 || !processedData || Object.keys(processedData).length === 0 || !chartData) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const handleLegendClick = (legendItem) => {
        const clickedBar = legendItem.dataKey;
        setDisabledBars((prev) => ({
            ...prev,
            [clickedBar]: !prev[clickedBar],
        }));
    };

    // Custom Tooltip component
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div
                    style={{
                        backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#333333',
                        border: 'none',
                        borderRadius: '8px',
                        padding: '10px',
                        color: textColor,
                    }}
                >
                    <p>{label}</p>
                    {payload.map((entry, index) => (
                        <p key={index} style={{ color: uniqueColors[entry.name] }}>
                            {`${entry.name}: ${entry.value.toLocaleString()}`}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={chartData}
                margin={isSmallScreen ? { top: 30, right: 20, bottom: 20, left: 30 } : { top: 20, right: 30, bottom: 10, left: 30 }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis
                    dataKey="year"
                    textAnchor='middle'
                    tick={{ fill: textColor, fontSize: isSmallScreen ? 10 : 12 }}
                />
                <YAxis
                    tick={{ fill: textColor }}
                    width={30}
                />
                <Tooltip
                    content={<CustomTooltip />}
                    cursor={{ fill: 'grey', opacity: 0.1 }}
                />
                <Legend
                    align="center"
                    wrapperStyle={{ color: textColor, paddingTop: isSmallScreen ? 30 : 20 }}
                    onClick={handleLegendClick}
                    formatter={(value) => (
                        <span
                            style={{
                                color: disabledBars[value] ? '#888' : textColor,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                />
                {allBarNames.map((key) => (
                    <Bar
                        key={key}
                        dataKey={key}
                        fill={uniqueColors[key]}
                        name={key}
                        hide={disabledBars[key]}
                    >
                        {showLabels && (
                            <LabelList
                                dataKey={key}
                                position="top"
                                fill={textColor}
                                fontSize={isSmallScreen ? 10 : 12}
                                formatter={(value) => (value > 0 ? value.toLocaleString() : '')}
                            />
                        )}
                    </Bar>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default YearlyBarChart;