import React from 'react';
import { Typography, useTheme, Grid, Box, useMediaQuery } from '@mui/material';
import Loading from '../../components/Loading';
import dayjs from 'dayjs';
import TopDayTable from '../../charts/reportCharts/TopDayTable';
import TopDayPieChart from '../../charts/reportCharts/TopDayPieChart';
import TopWeekTable from '../../charts/reportCharts/TopWeekTable';
import TopWeekPieChart from '../../charts/reportCharts/TopWeekPieChart';
import ReportWeeklyChart from '../../charts/reportCharts/ReportWeeklyChart';
import ReportDailyChart from '../../charts/reportCharts/ReportDailyChart';
import ReportMonthlyChart from '../../charts/reportCharts/ReportMonthlyChart';
import WeeklyCountTable from '../../charts/reportCharts/WeeklyCountTable';
import MonthlyCountTable from '../../charts/reportCharts/MonthlyCountTable';
import './style.css';

const ReportContent = ({
    selectedSensor,
    extremeData,
    extremeDataLoading,
    selectedMonth,
    selectedYear,
    monthlyProcessedData,
    monthlyDataIsLoading,
}) => {
    
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const highestData = extremeData && selectedSensor
        ? extremeData[selectedSensor]?.highestDay?.[`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}`]
        : null;
    
    const lowestData = extremeData && selectedSensor
        ? extremeData[selectedSensor]?.lowestDay?.[`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}`]
        : null;
    
    const highestWeekData = extremeData && selectedSensor
        ? extremeData[selectedSensor]?.highestWeekForMonth?.[`${selectedYear}`]?.[`${selectedMonth.toString().padStart(2, '0')}`]
        : null;

    const lowestWeekData = extremeData && selectedSensor
        ? extremeData[selectedSensor]?.lowestWeekForMonth?.[`${selectedYear}`]?.[`${selectedMonth.toString().padStart(2, '0')}`]
        : null;
    
    const dailyData = monthlyProcessedData
        ? monthlyProcessedData['dailyData']
        : null;
    
    const weeklyTableData = monthlyProcessedData
        ? monthlyProcessedData['weeklyData']
        : null;
    
    const monthlyTableData = monthlyProcessedData
        ? monthlyProcessedData['monthlyData']
        : null;

    if (!selectedSensor) {
        return <Loading />;
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid container item xs={12} justifyContent="center" spacing={2}>
                    <Grid item xs={isSmallScreen ? 12 : isTablet ? 6 : 3}>
                        <Box height="30vh" backgroundColor={theme.palette.background.paper} borderRadius='15px' sx={{ boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)' }}>
                            <TopDayPieChart
                                data={highestData ? [highestData] : []}
                                isLoading={extremeDataLoading}
                                selectedSensor={selectedSensor}
                                selectedMonth={selectedMonth}
                                selectedYear={selectedYear}
                                title="PEAK DAY"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : isTablet ? 6 : 3}>
                        <Box height="30vh" backgroundColor={theme.palette.background.paper} borderRadius='15px' sx={{ boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)' }}>
                            <TopDayPieChart
                                data={lowestData ? [lowestData] : []}
                                isLoading={extremeDataLoading}
                                selectedSensor={selectedSensor}
                                selectedMonth={selectedMonth}
                                selectedYear={selectedYear}
                                title="LOWEST DAY"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : isTablet ? 6 : 3}>
                        <Box height="30vh" backgroundColor={theme.palette.background.paper} borderRadius='15px' sx={{ boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)' }}>
                            <TopWeekPieChart
                                data={highestWeekData ? [highestWeekData] : []}
                                isLoading={extremeDataLoading}
                                selectedSensor={selectedSensor}
                                selectedMonth={selectedMonth}
                                selectedYear={selectedYear}
                                title="PEAK WEEK"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : isTablet ? 6 : 3}>
                        <Box height="30vh" backgroundColor={theme.palette.background.paper} borderRadius='15px' sx={{ boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)' }}>
                            <TopWeekPieChart
                                data={lowestWeekData ? [lowestWeekData] : []}
                                isLoading={extremeDataLoading}
                                selectedSensor={selectedSensor}
                                selectedMonth={selectedMonth}
                                selectedYear={selectedYear}
                                title="LOWEST WEEK"
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container item xs={12} justifyContent="center" spacing={2}>
                    <Grid item xs={isSmallScreen ? 12 : isTablet ? 6 : 3}>
                        <TopDayTable
                            title="PEAK DAY"
                            data={highestData ? [highestData] : []}
                            isLoading={extremeDataLoading}
                            selectedSensor={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                        />
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : isTablet ? 6 : 3}>
                        <TopDayTable
                            title="LOWEST DAY"
                            data={lowestData ? [lowestData] : []}
                            isLoading={extremeDataLoading}
                            selectedSensor={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                        />
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : isTablet ? 6 : 3}>
                        <TopWeekTable
                            title="HIGHEST WEEK"
                            headings={["Logic Name", "In", "Out", "Day"]}
                            data={highestWeekData ? [highestWeekData] : []}
                            isLoading={extremeDataLoading}
                            selectedSensor={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                        />
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : isTablet ? 6 : 3}>
                        <TopWeekTable
                            title="LOWEST WEEK"
                            headings={["Logic Name", "In", "Out", "Day"]}
                            data={lowestWeekData ? [lowestWeekData] : []}
                            isLoading={extremeDataLoading}
                            selectedSensor={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Box height="60vh" backgroundColor={theme.palette.background.paper} borderRadius='15px' p="1rem" sx={{ boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)' }}>
                        <Typography variant="h2" sx={{ color: theme.palette.text.white, fontWeight: 'bold', mb: "1rem" }}>
                            DAILY COUNT
                        </Typography>
                        {selectedSensor ? (
                            <ReportDailyChart
                                dailyData={dailyData || []}
                                dailyLoading={monthlyDataIsLoading}
                                selectedMac={selectedSensor}
                                selectedDateRange={[
                                    {
                                        startDate: dayjs().set('year', selectedYear).set('month', selectedMonth - 1).set('date', 1),
                                        endDate: dayjs().set('year', selectedYear).set('month', selectedMonth - 1).endOf('month')
                                    }
                                ]}
                            /> 
                        ) : (
                            <Loading />
                        )}
                    </Box>
                </Grid>

                <Grid item xs={isSmallScreen ? 12 : 6}>
                    <Box height="60vh" backgroundColor={theme.palette.background.paper} borderRadius='15px' p="1rem" sx={{ boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)' }}>
                        <Typography variant="h2" sx={{ color: theme.palette.text.white, fontWeight: 'bold', mb: "1rem" }}>
                            Weekly Count
                        </Typography>
                        {selectedSensor ? (
                            <ReportWeeklyChart
                                weeklyData={weeklyTableData || []}
                                weeklyLoading={monthlyDataIsLoading}
                                selectedMac={selectedSensor}
                                selectedMonth={selectedMonth}
                                selectedYear={selectedYear}
                            />
                        ) : (
                            <Loading />
                        )}
                    </Box>
                </Grid>

                <Grid item xs={isSmallScreen ? 12 : 6}>
                    <Box height="60vh" backgroundColor={theme.palette.background.paper} borderRadius='15px' p="1rem" sx={{ boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)' }}>
                        <Typography variant="h2" sx={{ color: theme.palette.text.white, fontWeight: 'bold', mb: "1rem" }}>
                            Month Count
                        </Typography>
                        {selectedSensor ? (
                            <ReportMonthlyChart
                                monthlyData={monthlyTableData || []}
                                monthlyLoading={monthlyDataIsLoading}
                                selectedSensor={selectedSensor}
                                selectedYear={selectedYear}
                            />
                        ) : (
                            <Loading />
                        )}
                    </Box>
                </Grid>

                <Grid container item xs={12} justifyContent="center" mb={5} spacing={2}>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                        <WeeklyCountTable
                            title="WEEKLY COUNTS"
                            data={weeklyTableData || []}
                            isLoading={monthlyDataIsLoading}
                            selectedSensor={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                        />
                    </Grid>

                    <Grid item xs={isSmallScreen ? 12 : 6}>
                        <MonthlyCountTable
                            title="MONTH COUNTS"
                            data={monthlyTableData || []}
                            isLoading={monthlyDataIsLoading}
                            selectedSensor={selectedSensor}
                            selectedYear={selectedYear}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ReportContent;
