import React from 'react';
import { Box, Typography } from '@mui/material';
import EdgesensorHighIcon from '@mui/icons-material/EdgesensorHigh';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const StatBox = ({ title, value, isSmallScreen, isTablet, index }) => {
    const colors = ["#FFE2E5", "#FFF4DE", "#DCFCE7", "#F3E8FF"];
    const iconColors = ["#FA5A7D", "#FF947A", "#3CD856", "#BF83FF"];
    const icons = [
        <EdgesensorHighIcon sx={{ fontSize: '1.5rem', color: 'white' }} />,
        <LocationOnIcon sx={{ fontSize: '1.5rem', color: 'white' }} />,
        <WifiOffIcon sx={{ fontSize: '1.5rem', color: 'white' }} />,
        <DirectionsRunIcon sx={{ fontSize: '1.5rem', color: 'white' }} />,
    ];

    // Determine the background color and icon based on the index
    const backgroundColor = colors[index % colors.length];
    const iconColor = iconColors[index % iconColors.length];
    const icon = icons[index % icons.length];

    // JSX
    return (
        <Box
            gridColumn={isSmallScreen ? "span 12" : isTablet ? "span 3" : "span 2"}
            gridRow="span 1"
            borderRadius="0.55rem"
            position="relative"
            overflow="hidden"
            backgroundColor={backgroundColor}
            padding="1rem"
            sx={{
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            {/* Icon with circular background */}
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    width: '2.5rem',
                    height: '2.5rem',
                    borderRadius: '50%',
                    backgroundColor: iconColor,
                    mb: '1rem',
                }}
            >
                {icon} {/* Render the icon based on the index */}
            </Box>

            {/* Value */}
            <Typography variant={isTablet ? "h3" : "h2"} fontWeight="600" sx={{ mt: '0.5rem', ml: '0.5rem', color: 'black' }}>
                {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </Typography>

            {/* Title */}
            <Typography variant="h6" sx={{ mt: '0.5rem', mb: '1rem', ml: '0.5rem', color: 'black' }}>
                {title}
            </Typography>
        </Box>
    );
};

export default StatBox;