import React from 'react';
import { Box, FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

const MonthPicker = ({ selectedMonthYear, setSelectedMonthYear }) => {
    const handleMonthYearChange = (date) => {
        const month = dayjs(date).month() + 1;
        const year = dayjs(date).year();
        setSelectedMonthYear({ month, year });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box display="flex" alignItems="center" gap={2}>
                <FormControl sx={{ minWidth: 120 }}>
                    <DatePicker
                        label={'Select Month and Year'}
                        views={['year', 'month']}
                        value={selectedMonthYear ? dayjs().year(selectedMonthYear.year).month(selectedMonthYear.month - 1) : null}
                        onChange={handleMonthYearChange}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '40px',
                            },
                            '& .MuiInputLabel-root': {
                                transform: 'translate(14px, 10px) scale(1)',
                            },
                            '& .MuiInputLabel-shrink': {
                                transform: 'translate(14px, -6px) scale(0.75)',
                            },
                        }}
                    />
                </FormControl>
            </Box>
        </LocalizationProvider>
    );
};

export default MonthPicker;