import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { CUSTOM_COLORS } from '../components/gradientDefs';

const HourlyPieChart = ({ chartIsLoading, selectedMac, processedData, error, showLabels }) => {
    const themeMode = useSelector(({ global }) => global.mode);
    const [disabledLegends, setDisabledLegends] = useState([]);

    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const hourlyData = useMemo(() => processedData?.[selectedMac] || {}, [processedData, selectedMac]);

    const aggregateHourlyData = (hourlyData) => {
        const aggregateData = {};
        Object.values(hourlyData).forEach((hourCounts) => {
            Object.entries(hourCounts).forEach(([logic, count]) => {
                aggregateData[logic] = (aggregateData[logic] || 0) + count;
            });
        });
        return Object.entries(aggregateData).map(([key, value]) => ({ name: key, value }));
    };

    const pieData = useMemo(() => aggregateHourlyData(hourlyData), [hourlyData]);

    const uniqueColors = useMemo(() => {
        const shuffledColors = shuffleArray([...CUSTOM_COLORS]);
        return pieData.reduce((acc, entry, index) => {
            acc[entry.name] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});
    }, [pieData]);

    if (chartIsLoading) {
        <Loading />
    }

    if (error || error?.status === 404 || !processedData || Object.keys(processedData).length === 0) {
        return <NoDataAvailableMessage />;
    }

    if (!hourlyData || Object.keys(hourlyData).length === 0) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const handleLegendClick = (entry) => {
        const clickedLegend = entry.value;
        setDisabledLegends((prev) =>
            prev.includes(clickedLegend)
                ? prev.filter((item) => item !== clickedLegend)
                : [...prev, clickedLegend]
        );
    };

    const filteredData = pieData.filter((entry) => !disabledLegends.includes(entry.name));

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie
                    data={filteredData}
                    cx="50%"
                    cy="50%"
                    innerRadius="40%"
                    outerRadius="80%"
                    paddingAngle={5}
                    dataKey="value"
                    labelLine={false}
                    stroke="none"
                >
                    {pieData.map((entry, index) =>
                        !disabledLegends.includes(entry.name) ? (
                            <Cell
                                key={`cell-${entry.name}`}
                                fill={uniqueColors[entry.name]}
                            />
                        ) : null
                    )}
                </Pie>
                <Tooltip
                    contentStyle={{
                        backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#333333',
                        border: 'none',
                        borderRadius: '8px',
                        color: textColor,
                    }}
                    formatter={(value, name) => {
                        const total = pieData.reduce((acc, entry) => acc + entry.value, 0);
                        const percentage = ((value / total) * 100).toFixed(2);
                        return [`${value} (${percentage}%)`, name];
                    }}
                />
                <Legend
                    wrapperStyle={{ color: textColor }}
                    onClick={handleLegendClick}
                    formatter={(value) => (
                        <span
                            style={{
                                color: disabledLegends.includes(value) ? '#888' : textColor,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                    payload={pieData.map((entry) => ({
                        value: entry.name,
                        type: 'circle',
                        id: entry.name,
                        color: disabledLegends.includes(entry.name)
                            ? '#888'
                            : uniqueColors[entry.name],
                    }))}
                    layout="horizontal"
                    align="center"
                    verticalAlign="bottom"
                />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default HourlyPieChart;