import React, { useState, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { CUSTOM_COLORS } from '../components/gradientDefs';
import { useTheme, useMediaQuery } from '@mui/material';

const MonthlyLineChart = ({ selectedSensor, selectedYear, processedData, error, showLabels, chartIsLoading }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [activeLegends, setActiveLegends] = useState([]);
    const themeMode = useSelector(({ global }) => global.mode);

    // Function to shuffle the CUSTOM_COLORS array
    const shuffleColors = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    // Function to convert month number to three-letter abbreviation
    const getMonthAbbreviation = (monthNumber) => {
        const date = new Date(2023, monthNumber - 1, 1); // Use any year (2023 is arbitrary)
        return date.toLocaleString('default', { month: 'short' }).toUpperCase();
    };

    // Process data and assign unique colors
    const { chartData, allLineNames, uniqueColors } = useMemo(() => {
        if (!processedData || chartIsLoading) return { chartData: [], allLineNames: [], uniqueColors: {} };

        const selectedYearValue = selectedYear instanceof Date ? selectedYear.getFullYear() : selectedYear.$y || selectedYear;

        const yearData = processedData[selectedSensor]?.[selectedYearValue];
        if (!yearData) return { chartData: [], allLineNames: [], uniqueColors: {} };

        const monthNames = Array.from({ length: 12 }, (_, i) => {
            const month = i + 1;
            return month < 10 ? `0${month}` : `${month}`;
        });

        const uniqueKeys = new Set();
        const chartData = monthNames.map(month => {
            const entry = { month };
            if (yearData.hasOwnProperty(month)) {
                Object.entries(yearData[month]).forEach(([categoryName, value]) => {
                    entry[categoryName] = value;
                    uniqueKeys.add(categoryName);
                });
            } else {
                Object.keys(yearData[Object.keys(yearData)[0]]).forEach(categoryName => {
                    entry[categoryName] = 0;
                });
            }
            return entry;
        });

        const sortedLineNames = Array.from(uniqueKeys).sort((a, b) => {
            const aNum = parseFloat(a);
            const bNum = parseFloat(b);
            if (!isNaN(aNum) && !isNaN(bNum)) {
                return aNum - bNum;
            }
            return a.localeCompare(b);
        });

        const shuffledColors = shuffleColors([...CUSTOM_COLORS]);
        const uniqueColors = sortedLineNames.reduce((acc, lineName, index) => {
            acc[lineName] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});

        return {
            chartData,
            allLineNames: sortedLineNames,
            uniqueColors,
        };
    }, [processedData, chartIsLoading, selectedSensor, selectedYear]);

    if (chartIsLoading) {
        return <Loading />;
    }

    if (error || error?.status === 404 || !processedData || Object.keys(processedData).length === 0 || !chartData.length) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const handleLegendClick = (legendItem) => {
        setActiveLegends((prevActiveLegends) =>
            prevActiveLegends.includes(legendItem.dataKey)
                ? prevActiveLegends.filter((item) => item !== legendItem.dataKey)
                : [...prevActiveLegends, legendItem.dataKey]
        );
    };

    const hiddenLines = activeLegends.reduce((acc, key) => {
        acc[key] = true;
        return acc;
    }, {});

    // Custom Tooltip component
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div
                    style={{
                        backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#333333',
                        border: 'none',
                        borderRadius: '8px',
                        padding: '10px',
                        color: textColor,
                    }}
                >
                    <p>{`Month: ${getMonthAbbreviation(label)}`}</p> {/* Format month in tooltip */}
                    {payload.map((entry, index) => (
                        <p key={index} style={{ color: uniqueColors[entry.name] }}>
                            {`${entry.name}: ${entry.value.toLocaleString()}`}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={chartData}
                margin={isSmallScreen ? { top: 30, right: 20, bottom: 20, left: 20 } : { top: 20, right: 30, bottom: 10, left: 20 }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis
                    dataKey="month"
                    textAnchor='middle'
                    tick={{ fill: textColor, fontSize: isSmallScreen ? 10 : 12 }}
                    tickFormatter={(value) => getMonthAbbreviation(value)}
                />
                <YAxis
                    tick={{ fill: textColor }}
                    width={30}
                />
                <Tooltip
                    content={<CustomTooltip />}
                />
                <Legend
                    align="center"
                    onClick={handleLegendClick}
                    wrapperStyle={{
                        paddingTop: isSmallScreen ? 30 : 20,
                    }}
                    formatter={(value) => (
                        <span
                            style={{
                                color: hiddenLines[value] ? theme.palette.text.disabled : theme.palette.text.primary,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                />
                {allLineNames.map((lineId) => (
                    <Line
                        key={lineId}
                        type="monotone"
                        dataKey={lineId}
                        strokeWidth={2}
                        dot={true}
                        name={lineId}
                        hide={hiddenLines[lineId]}
                        stroke={uniqueColors[lineId]}
                    >
                        {showLabels && (
                            <LabelList
                                dataKey={lineId}
                                position="top"
                                fill={textColor}
                                fontSize={isSmallScreen ? 10 : 12}
                                formatter={(value) => (value > 0 ? value.toLocaleString() : '')}
                            />
                        )}
                    </Line>
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default MonthlyLineChart;