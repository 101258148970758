import React, { useState, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { CUSTOM_COLORS } from '../components/gradientDefs';
import { useTheme, useMediaQuery } from '@mui/material';

const YearlyLineChart = ({ selectedMac, processedData, chartIsLoading, error, showLabels }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [activeLegends, setActiveLegends] = useState([]);
    const themeMode = useSelector(({ global }) => global.mode);

    // Function to shuffle the CUSTOM_COLORS array
    const shuffleColors = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    // Process data and assign unique colors
    const { chartData, allLineNames, uniqueColors } = useMemo(() => {
        // Return null if no processedData or loading
        if (!processedData || chartIsLoading) return { chartData: null, allLineNames: null, uniqueColors: null };

        const filteredData = selectedMac ? (processedData && processedData[selectedMac]) || {} : processedData || {};

        const lastFiveYears = Array.from({ length: 5 }, (_, index) => {
            const currentYear = new Date().getFullYear();
            return (currentYear - index).toString();
        });

        const keys = new Set();
        lastFiveYears.forEach(year => {
            const yearData = filteredData[year] || {};
            Object.keys(yearData).forEach(key => keys.add(key));
        });

        // If no keys are found, return null
        if (keys.size === 0) return { chartData: null, allLineNames: null, uniqueColors: null };

        const sortedLineNames = Array.from(keys).sort((a, b) => {
            const aNum = parseFloat(a);
            const bNum = parseFloat(b);
            if (!isNaN(aNum) && !isNaN(bNum)) {
                return aNum - bNum;
            }
            return a.localeCompare(b);
        });

        const chartData = lastFiveYears.reverse().map(year => {
            const yearData = filteredData[year] || {};
            const yearValues = { year };

            sortedLineNames.forEach(category => {
                yearValues[category] = yearData[category] || 0;
            });

            return yearValues;
        });

        // If chartData is empty, return null
        if (chartData.length === 0) return { chartData: null, allLineNames: null, uniqueColors: null };

        const shuffledColors = shuffleColors([...CUSTOM_COLORS]);
        const uniqueColors = sortedLineNames.reduce((acc, barName, index) => {
            acc[barName] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});

        return {
            chartData,
            allLineNames: sortedLineNames,
            uniqueColors,
        };
    }, [processedData, chartIsLoading, selectedMac]);

    if (chartIsLoading) {
        return <Loading />;
    }

    if (error || error?.status === 404 || !processedData || Object.keys(processedData).length === 0 || !chartData) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const handleLegendClick = (legendItem) => {
        setActiveLegends((prevActiveLegends) =>
            prevActiveLegends.includes(legendItem.dataKey)
                ? prevActiveLegends.filter((item) => item !== legendItem.dataKey)
                : [...prevActiveLegends, legendItem.dataKey]
        );
    };

    const hiddenLines = activeLegends.reduce((acc, key) => {
        acc[key] = true;
        return acc;
    }, {});

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={chartData}
                margin={isSmallScreen ? { top: 30, right: 20, bottom: 20, left: 30 } : { top: 20, right: 30, bottom: 10, left: 30 }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis
                    dataKey="year"
                    textAnchor='middle'
                    tick={{ fill: textColor, fontSize: isSmallScreen ? 10 : 12 }}
                />
                <YAxis
                    tick={{ fill: textColor }}
                    width={30}
                />
                <Tooltip
                    contentStyle={{
                        backgroundColor: theme.palette.background.default,
                        border: 'none',
                        borderRadius: '8px',
                        color: theme.palette.text.primary,
                    }}
                />
                <Legend
                    align="center"
                    onClick={handleLegendClick}
                    wrapperStyle={{
                        paddingTop: isSmallScreen ? 30 : 20,
                    }}
                    formatter={(value) => (
                        <span
                            style={{
                                color: hiddenLines[value] ? theme.palette.text.disabled : theme.palette.text.primary,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                />
                {allLineNames.map((lineId) => (
                    <Line
                        key={lineId}
                        type="monotone"
                        dataKey={lineId}
                        strokeWidth={2}
                        dot={true}
                        name={lineId}
                        hide={hiddenLines[lineId]}
                        stroke={uniqueColors[lineId]}
                    >
                        {showLabels && (
                            <LabelList
                                dataKey={lineId}
                                position="top"
                                fill={textColor}
                                fontSize={isSmallScreen ? 10 : 12}
                                formatter={(value) => (value > 0 ? value.toLocaleString() : '')}
                            />
                        )}
                    </Line>
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default YearlyLineChart;