import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

// Custom formatting functions
const formatDate = (date) => {
    const d = new Date(date);
    return d.toLocaleDateString('en-GB');
};

const formatTime = (date) => {
    const d = new Date(date);
    return d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

// Function to calculate time difference in hours, minutes, and seconds
const calculateDuration = (offlineTime, onlineTime) => {
    const offline = new Date(offlineTime);
    const online = new Date(onlineTime);
    const diff = Math.abs(online - offline);
    
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
};

const DowntimeTable = ({ downtimeData }) => {
    return (
        <TableContainer component={Paper} sx={{ mt: 2, borderRadius: '15px' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><Typography variant="h6">Date</Typography></TableCell>
                        <TableCell><Typography variant="h6">Offline</Typography></TableCell>
                        <TableCell><Typography variant="h6">Online</Typography></TableCell>
                        <TableCell><Typography variant="h6">Duration</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {downtimeData.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={4} align="center">
                                <Typography>No data available</Typography>
                            </TableCell>
                        </TableRow>
                    ) : (
                        downtimeData.map((entry, index) => (
                            <TableRow key={index}>
                                <TableCell>{formatDate(entry.offlineTime)}</TableCell>
                                <TableCell>{formatTime(entry.offlineTime)}</TableCell>
                                <TableCell>
                                    {entry.onlineTime 
                                        ? formatTime(entry.onlineTime) 
                                        : <Typography color="red">Pending</Typography>}
                                </TableCell>
                                <TableCell>
                                    {entry.onlineTime 
                                        ? calculateDuration(entry.offlineTime, entry.onlineTime) 
                                        : <Typography color="red">Pending</Typography>}
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DowntimeTable;