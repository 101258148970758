import React from 'react';
import { Typography, Box, useTheme } from "@mui/material";

const Header = ({ title, subtitle, isSmallScreen }) => {
    const theme = useTheme();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box>
                <Typography 
                    variant={isSmallScreen ? "h3" : "h2"}
                    color={theme.palette.text.white} 
                    fontWeight="bold" 
                    sx={{ mb: "5px" }}
                >
                    {title}
                </Typography>
                <Typography 
                    variant={isSmallScreen ? "subtitle1" : "h5"}
                    color={theme.palette.text.grey}
                    marginBottom={isSmallScreen ? "20px" : "0px"}
                >
                    {subtitle}
                </Typography>
            </Box>
        </Box>
    );
};

export default Header;
