import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import DevicesHeader from './DevicesHeader';
import DeviceList from './DeviceList';
import AddDeviceDialogBox from './AddDeviceDialogBox';
import ErrorSnackbar from '../../components/ErrorSnackbar';
import { useGetDevicesQuery } from '../../services/api';

const Devices = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { company } = useSelector((state) => state.global);

    // State for dialog and snackbar
    const [openDialog, setOpenDialog] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

    // API hooks
    const { data: devicesData, refetch: refetchDevices } = useGetDevicesQuery(company);

    // Refetch devices every 60 seconds
    useEffect(() => {
        const interval = setInterval(() => refetchDevices(), 60000);
        return () => clearInterval(interval);
    }, [refetchDevices]);

    const filteredDevices = useMemo(() => {
        return devicesData ? devicesData.filter(device => device.addedToDashboard && device.company === company) : [];
    }, [devicesData, company]);

    // Handle opening and closing the dialog
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <DevicesHeader
                handleOpenDialog={handleOpenDialog}
                theme={theme}
            />
            <DeviceList
                filteredDevices={filteredDevices}
                theme={theme}
                isSmallScreen={isSmallScreen}
            />
            <AddDeviceDialogBox
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                setSnackbar={setSnackbar}
                theme={theme}
                refetchDevices={refetchDevices}
            />
            <ErrorSnackbar
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Box>
    );
};

export default Devices;