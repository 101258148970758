import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { useGetSitesQuery, useDisableSiteMutation, useEnableSiteMutation, useRemoveSiteMutation } from '../../services/api';
import ErrorSnackBar from '../../components/ErrorSnackbar';
import mainLocationsInQatar from '../../constants/mainLocations';
import Loading from '../../components/Loading';
import SitesHeader from './SitesHeader';
import SitesContent from './SiteContent';
import AddSiteDialogBox from './AddSiteDialogBox';

const Sites = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { company } = useSelector((state) => state.global);

    const [openDialog, setOpenDialog] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const { data: sitesData, refetch, isError: siteDataError } = useGetSitesQuery(company);
    const [disableSiteMutation] = useDisableSiteMutation();
    const [enableSiteMutation] = useEnableSiteMutation();
    const [removeSiteMutation] = useRemoveSiteMutation();

    useEffect(() => {
        refetch();
    }, [refetch]);

    const handleSiteStatusChange = async (siteId, newStatus) => {
        try {
        if (newStatus === 'disabled') {
            await disableSiteMutation(siteId);
            setSnackbar({ open: true, message: `Site disabled successfully`, severity: "success" });
        } else if (newStatus === 'enabled') {
            await enableSiteMutation(siteId);
            setSnackbar({ open: true, message: `Site enabled successfully`, severity: "success" });
        }
            refetch();
        } catch (error) {
            console.error('Error updating site status:', error);
            setSnackbar({ open: true, message: 'Failed to update site status. Please try again later.', severity: "error" });
        }
    };

    const handleRemoveSite = async (siteId) => {
        try {
            // Fetch the site details to check for connected devices
            const site = sitesData?.find((site) => site._id === siteId);

            if (!site) {
                setSnackbar({ open: true, message: "Site not found.", severity: "error" });
                return;
            }

            // Check if the site has connected devices
            if (site.devices > 0 || site.connectedMACs.length > 0) {
                setSnackbar({ open: true, message: "Cannot remove site when a device is connected.", severity: "error" });
                return;
            }

            // If no devices are connected, proceed with removal
            await removeSiteMutation(siteId);
            refetch();
            setSnackbar({ open: true, message: "Site removed successfully", severity: "success" });
        } catch (error) {
            console.error('Error removing site:', error);
            setSnackbar({ open: true, message: "Failed to remove site. Please try again later.", severity: "error" });
        }
    };

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <SitesHeader handleOpenDialog={handleOpenDialog} isSmallScreen={isSmallScreen} />
            {siteDataError ? (
                <Loading />
            ) : (
                <SitesContent
                sitesData={sitesData}
                siteDataError={siteDataError}
                isSmallScreen={isSmallScreen}
                handleSiteStatusChange={handleSiteStatusChange}
                handleRemoveSite={handleRemoveSite}
                />
            )}
            <AddSiteDialogBox
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                setSnackbar={setSnackbar}
                sitesData={sitesData}
                mainLocationsInQatar={mainLocationsInQatar}
                refetch={refetch}
            />
            <ErrorSnackBar
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Box>
    );
};

export default Sites;
