import React from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, useTheme } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { styled } from '@mui/system';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import Loading from '../../components/Loading';

// Custom CSS for invisible scrollbar
const StyledTableContainer = styled(TableContainer)`
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    & {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
    }
`;

const MonthlyCountTable = ({ title, isLoading, data, selectedSensor, selectedYear }) => {
    const theme = useTheme();

    if (isLoading) {
        return <Loading />
    }

    if (!data || !selectedSensor || !selectedYear) {
        return <NoDataAvailableMessage />
    }

    const monthsShortForms = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const renderDataRows = () => {
        const rows = [];
        const allLogicNames = getAllLogicNames();

        let previousTotal = 0;
        for (const sensorKey in data) {
            if (sensorKey !== selectedSensor || !data[sensorKey][selectedYear]) continue;
            const yearData = data[sensorKey][selectedYear];
            const months = Object.keys(yearData).sort((a, b) => parseInt(a) - parseInt(b));

            for (let i = 0; i < months.length; i++) {
                const monthKey = months[i];
                const monthData = yearData[monthKey];
                const currentTotal = Object.values(monthData).reduce((acc, val) => acc + val, 0);

                const comparisonIcon = previousTotal > currentTotal ? <ArrowDownward color="error" /> : <ArrowUpward color="success" />;
                previousTotal = currentTotal;

                rows.push(
                    <TableRow key={`${sensorKey}-${selectedYear}-${monthKey}`}>
                        <TableCell align="center">{monthsShortForms[parseInt(monthKey) - 1]}</TableCell>
                        {allLogicNames.map(logic => {
                            const count = monthData[logic] || '-';
                            return (
                                <TableCell key={logic} align="center">{count}</TableCell>
                            );
                        })}
                        <TableCell align="center">{comparisonIcon}</TableCell>
                    </TableRow>
                );
            }
        }
        return rows;
    };

    const getAllLogicNames = () => {
        const allLogicNames = new Set();
        for (const sensorKey in data) {
            if (sensorKey !== selectedSensor || !data[sensorKey][selectedYear]) continue;
            const yearData = data[sensorKey][selectedYear];
            for (const monthKey in yearData) {
                const monthData = yearData[monthKey];
                for (const logic in monthData) {
                    allLogicNames.add(logic);
                }
            }
        }
        return Array.from(allLogicNames);
    };

    return (
        <Box
            backgroundColor={theme.palette.background.paper}
            borderRadius="15px"
            display="flex"
            flexDirection="column"
            position="relative"
            boxShadow='8px 8px 20px rgba(0, 0, 0, 0.1)'
        >
            <Typography variant="h3" sx={{ color: theme.palette.text.white, m: "2%", p: "2%", fontWeight: 'bold', textAlign: "center" }}>{title}</Typography>
            <StyledTableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Month</TableCell>
                            {getAllLogicNames().map((logic, index) => (
                                <TableCell key={index} align="center">{logic}</TableCell>
                            ))}
                            <TableCell align="center">Comparison</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderDataRows()}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </Box>
    );
};

export default MonthlyCountTable;
