import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, useTheme } from '@mui/material';
import { AddBoxOutlined } from '@mui/icons-material';
import Header from '../../components/Header';

const SitesHeader = ({ handleOpenDialog }) => {
    const theme = useTheme();

    const { role } = useSelector((state) => state.global);
    const isAdmin = role === 'Admin';

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="1rem"
            padding="1rem"
            backgroundColor={theme.palette.background.paper}
            borderRadius="15px"
            sx={{
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Header title="SITES" subtitle="View your sites" />
            {isAdmin && (
                <Button
                    onClick={handleOpenDialog}
                    sx={{
                        backgroundColor: theme.palette.text.white,
                        color: theme.palette.text.black,
                        padding: "0rem 1rem",
                        '&:hover': {
                            backgroundColor: theme.palette.text.white,
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                        m: '0.5rem'
                    }}
                >
                    <AddBoxOutlined sx={{ marginRight: "8px" }} />
                    Add Site
                </Button>
            )}
        </Box>
    );
};

export default SitesHeader;