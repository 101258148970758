import React from 'react';
import { Box, Button, MenuItem, Select, TextField, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DeviceDetailsForm = ({ device, deviceDetails, handleInputChange, handleDeviceSelect, handleUpdateDeviceDetails, filteredDevices, isSmallScreen }) => {
    const theme = useTheme();

    return (
        <Box
            p={4}
            borderRadius={5}
            sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.secondary.main,
                width: '100%',
                mt: 2,
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography variant="h3" sx={{ fontSize: '2rem', fontWeight: 'bold', color: theme.palette.text.white, mb: '1rem' }}>
                Device Details
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{ color: theme.palette.text.white }}>Select Device</Typography>
                    <Select
                        fullWidth
                        value={device}
                        onChange={handleDeviceSelect}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Select Device
                        </MenuItem>
                        {filteredDevices.map((device) => (
                            <MenuItem key={device._id} value={device.device_id.name}>
                                {device.device_id.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Change Device Name</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={deviceDetails.name || ''}
                        onChange={(e) => handleInputChange(e, 'name')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Entry - Exit</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={deviceDetails.entry || ''}
                        onChange={(e) => handleInputChange(e, 'entry')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Exit - Entry</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={deviceDetails.exit || ''}
                        onChange={(e) => handleInputChange(e, 'exit')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Adult Entry - Exit</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={deviceDetails.adultEntry || ''}
                        onChange={(e) => handleInputChange(e, 'adultEntry')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Child Entry - Exit</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={deviceDetails.childEntry || ''}
                        onChange={(e) => handleInputChange(e, 'childEntry')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Adult Exit - Entry</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={deviceDetails.adultExit || ''}
                        onChange={(e) => handleInputChange(e, 'adultExit')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Child Exit - Entry</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={deviceDetails.childExit || ''}
                        onChange={(e) => handleInputChange(e, 'childExit')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Male Entry - Exit</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={deviceDetails.maleEntry || ''}
                        onChange={(e) => handleInputChange(e, 'maleEntry')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Female Entry - Exit</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={deviceDetails.femaleEntry || ''}
                        onChange={(e) => handleInputChange(e, 'femaleEntry')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Male Exit - Entry</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={deviceDetails.maleExit || ''}
                        onChange={(e) => handleInputChange(e, 'maleExit')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ color: theme.palette.text.white }}>Female Exit - Entry</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={deviceDetails.femaleExit || ''}
                        onChange={(e) => handleInputChange(e, 'femaleExit')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        gap={3}
                        mt={2}
                    >
                        <Button
                            onClick={handleUpdateDeviceDetails}
                            variant="contained"
                            sx={{
                                backgroundColor: theme.palette.text.white,
                                color: theme.palette.text.black,
                                padding: "0.5rem 1rem",
                                '&:hover': {
                                    backgroundColor: theme.palette.text.white,
                                },
                                '&:focus': {
                                    outline: 'none',
                                },
                                m: '0.5rem'
                            }}
                        >
                            Update Device Details
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DeviceDetailsForm;