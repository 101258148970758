import React from 'react';
import { Box, useTheme } from '@mui/material';
import Lottie from 'lottie-react';
import { useSelector } from 'react-redux';
import LoadingAnimation from '../assets/animations/LoadingAnimation.json';
import DarkLoadingAnimation from '../assets/animations/DarkLoadingAnimation.json';

const Loading = ({ report }) => {
    const theme = useTheme();
    const themeMode = useSelector((state) => state.global.mode);

    // Define sizes for light and dark modes
    const animationSize = themeMode === 'light' ? { width: '150px', height: '150px' } : { width: '500px', height: '500px' };

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={report ? "90%" : "100%"}
            width="100%"
            backgroundColor={theme.palette.background.paper}
            borderRadius="15px"
        >
            <Lottie
                animationData={themeMode === 'light' ? LoadingAnimation : DarkLoadingAnimation}
                style={animationSize}
            />
        </Box>
    );
};

export default Loading;