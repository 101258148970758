import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddUserDialogBox = ({ open, handleCloseDialog, userData, handleInputChange, handleAddUser }) => {
    const theme = useTheme();

    const handleRoleChange = (event) => {
        handleInputChange(event);
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleCloseDialog}
            aria-describedby="add-user-dialog-description"
            PaperProps={{ sx: { backgroundColor: theme.palette.background.paper } }}
        >
            <DialogTitle variant="h2" fontWeight="bold">
                Add New User
            </DialogTitle>
            <DialogContent sx={{ width: '500px', padding: '20px' }}>
                <Box display="flex" flexDirection="column" gap={2}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        label="Name"
                        fullWidth
                        value={userData.name}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        name="email"
                        label="Email"
                        fullWidth
                        value={userData.email}
                        onChange={handleInputChange}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                            labelId="role-label"
                            id="role"
                            name="role"
                            label="Role"
                            value={userData.role}
                            onChange={handleRoleChange}
                        >
                            <MenuItem value="Admin">Admin</MenuItem>
                            <MenuItem value="User">User</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: '16px 24px' }}>
                <Button
                    onClick={handleCloseDialog}
                    variant="contained"
                    sx={{
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.main,
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleAddUser}
                    variant="contained"
                    sx={{
                        color: theme.palette.text.black,
                        backgroundColor: theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.main,
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                    }}
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddUserDialogBox;