import React from 'react';
import { Box } from '@mui/material';
import SiteBox from '../../components/SiteBox';
import Loading from '../../components/Loading';

const SitesContent = ({ sitesData, siteDataError, isSmallScreen, handleSiteStatusChange, handleRemoveSite }) => {
    return siteDataError ? (
        <Loading />
    ) : (
        <>
            {sitesData && sitesData.length > 0 ? (
                <Box display="grid" gridTemplateColumns={isSmallScreen ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} gap="20px">
                    {sitesData.map((site) => (
                        <SiteBox
                            key={site._id}
                            title={site.name}
                            location={site.location}
                            devicesConnected={site.devices}
                            latitude={site.latitude}
                            longitude={site.longitude}
                            siteId={site._id}
                            enabled={site.enabled}
                            onStatusChange={(siteId, newStatus) => handleSiteStatusChange(siteId, newStatus)}
                            onRemove={() => handleRemoveSite(site._id)}
                            isSmallScreen={isSmallScreen}
                        />
                    ))}
                </Box>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default SitesContent;
