import React from 'react';
import { Box } from '@mui/material';
import UserBox from '../../components/UserBox';
import NoDataAvailableMessage from '../../components/NoDataAvailable';

const UserList = ({ usersData, showSnackbar, refetchUsers, isSmallScreen }) => {
    const hasUsers = usersData && usersData.users && usersData.users.length > 0;

    return (
        <Box
            mt="20px"
            display="flex"
            flexWrap={hasUsers ? "wrap" : "nowrap"}
            justifyContent={hasUsers ? (isSmallScreen ? "center" : "start") : "center"}
            alignItems={hasUsers ? "flex-start" : "center"}
            height={hasUsers ? "auto" : "60vh"}
        >
            {hasUsers ? (
                usersData.users.map(user => (
                    <Box key={user._id} width="300px" mb="20px">
                        <UserBox user={user} userId={user._id} showSnackbar={showSnackbar} refetchUsers={refetchUsers} />
                    </Box>
                ))
            ) : (
                <NoDataAvailableMessage />
            )}
        </Box>
    );
};

export default UserList;