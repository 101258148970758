import React from 'react';
import { Box, Button } from '@mui/material';
import { AddBoxOutlined } from '@mui/icons-material';
import Header from '../../components/Header';
import { useSelector } from 'react-redux';

const DevicesHeader = ({ handleOpenDialog, theme }) => {

    const { role } = useSelector((state) => state.global);
    const isAdmin = role === "Admin";

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="2rem"
            padding="1rem"
            backgroundColor={theme.palette.background.paper}
            borderRadius="15px"
            sx={{
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Header title="DEVICES" subtitle="View your list of devices." />
            {isAdmin && (
                <Button
                    onClick={handleOpenDialog}
                    sx={{
                        backgroundColor: theme.palette.text.white,
                        color: theme.palette.text.black,
                        padding: "0rem 1rem",
                        '&:hover': {
                            backgroundColor: theme.palette.text.white,
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                        m: '0.5rem'
                    }}
                >
                    <AddBoxOutlined sx={{ marginRight: "8px" }} />
                    Add Device
                </Button>
            )}
        </Box>
    );
};

export default DevicesHeader;
