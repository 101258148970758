import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import DashboardOverviewChart from '../../charts/dashboardCharts/DashboardOverviewChart';

const OverviewChart = ({ data, isLoading }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            gridColumn={isSmallScreen ? "span 12" : "span 6"}
            gridRow="span 2"
            backgroundColor={theme.palette.background.paper}
            borderRadius="0.55rem"
            display="flex"
            flexDirection="column"
            position="relative"
            sx={{
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography variant='h3' sx={{ color: theme.palette.text.white, mt: "2%", ml: "2%", fontWeight: "bold" }}>
                OVERVIEW
            </Typography>
            <Box
                sx={{
                    height: "calc(100% - 50px)",
                    width: "100%",
                    position: "relative",
                }}
            >
                <DashboardOverviewChart overviewData={data} overviewLoading={isLoading} />
            </Box>
        </Box>
    )
}

export default OverviewChart;
