import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Loading from './Loading';

const NoDataAvailableMessage = ({ report }) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={report ? "90%" : "100%"}
            width="100%"
            backgroundColor={theme.palette.background.paper}
            borderRadius="15px"
        >
            {loading ? (
                <Loading />
            ) : (
                <Box>
                    <Typography variant="h1" sx={{ color: theme.palette.text.white, fontWeight: 'bold', textAlign: 'center' }}>
                        No Data Found
                    </Typography>
                    <Typography variant="h5" sx={{ color: theme.palette.text.white,  textAlign: 'center', mt: '1rem' }}>
                        Did it run away? 🏃‍♂️
                    </Typography>
                    <Typography variant="h5" sx={{ color: theme.palette.text.white,  textAlign: 'center', mt: '0.5rem' }}>
                        We’re on the case... check back soon!
                    </Typography>
                </Box>
            )}
        </Box>
    );
};
export default NoDataAvailableMessage;