import React, { useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { CUSTOM_COLORS } from '../components/gradientDefs';
import { useTheme, useMediaQuery } from '@mui/material';

const MonthlyBarChart = ({ selectedSensor, selectedYear, showLabels, processedData, error, chartIsLoading }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [disabledBars, setDisabledBars] = useState({});
    const themeMode = useSelector(({ global }) => global.mode);

    // Function to shuffle the CUSTOM_COLORS array
    const shuffleColors = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    // Function to convert month number to three-letter abbreviation
    const getMonthAbbreviation = (monthNumber) => {
        const date = new Date(2023, monthNumber - 1, 1);
        return date.toLocaleString('default', { month: 'short' }).toUpperCase();
    };

    // Process data and assign unique colors
    const { chartData, allBarNames, uniqueColors } = useMemo(() => {
        if (!processedData || chartIsLoading) return { chartData: [], allBarNames: [], uniqueColors: {} };

        const selectedYearValue = selectedYear instanceof Date ? selectedYear.getFullYear() : selectedYear.$y || selectedYear;

        const yearData = processedData[selectedSensor]?.[selectedYearValue];
        if (!yearData) return { chartData: [], allBarNames: [], uniqueColors: {} };

        const monthNames = Array.from({ length: 12 }, (_, i) => {
            const month = i + 1;
            return month < 10 ? `0${month}` : `${month}`;
        });

        const uniqueKeys = new Set();
        monthNames.forEach(month => {
            if (yearData.hasOwnProperty(month)) {
                Object.keys(yearData[month]).forEach(key => uniqueKeys.add(key));
            }
        });

        const chartData = monthNames.map(month => {
            const entry = { month };
            if (yearData.hasOwnProperty(month)) {
                Object.entries(yearData[month]).forEach(([categoryName, value]) => {
                    entry[categoryName] = value;
                });
            } else {
                uniqueKeys.forEach(key => entry[key] = 0);
            }
            return entry;
        });

        const sortedBarNames = Array.from(uniqueKeys).sort((a, b) => {
            const aNum = parseFloat(a);
            const bNum = parseFloat(b);
            if (!isNaN(aNum) && !isNaN(bNum)) {
                return aNum - bNum;
            }
            return a.localeCompare(b);
        });

        const shuffledColors = shuffleColors([...CUSTOM_COLORS]);
        const uniqueColors = sortedBarNames.reduce((acc, barName, index) => {
            acc[barName] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});

        return {
            chartData,
            allBarNames: sortedBarNames,
            uniqueColors,
        };
    }, [processedData, chartIsLoading, selectedSensor, selectedYear]);

    if (chartIsLoading) {
        return <Loading />;
    }

    if (error || error?.status === 404 || !processedData || Object.keys(processedData).length === 0 || !chartData.length) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const handleLegendClick = (legendItem) => {
        const clickedBar = legendItem.dataKey;
        setDisabledBars((prev) => ({
            ...prev,
            [clickedBar]: !prev[clickedBar],
        }));
    };

    // Custom Tooltip component
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div
                    style={{
                        backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#333333',
                        border: 'none',
                        borderRadius: '8px',
                        padding: '10px',
                        color: textColor,
                    }}
                >
                    <p>{getMonthAbbreviation(label)}</p>
                    {payload.map((entry, index) => (
                        <p key={index} style={{ color: uniqueColors[entry.name] }}>
                            {`${entry.name}: ${entry.value.toLocaleString()}`}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={chartData}
                margin={isSmallScreen ? { top: 30, right: 20, bottom: 20, left: 20 } : { top: 20, right: 30, bottom: 10, left: 20 }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis
                    dataKey="month"
                    textAnchor='middle'
                    tick={{ fill: textColor, fontSize: isSmallScreen ? 10 : 12 }}
                    tickFormatter={(value) => getMonthAbbreviation(value)}
                />
                <YAxis
                    tick={{ fill: textColor }}
                    width={30}
                />
                <Tooltip
                    content={<CustomTooltip />}
                    cursor={{ fill: 'grey', opacity: 0.1 }}
                />
                <Legend
                    align="center"
                    wrapperStyle={{ color: textColor, paddingTop: isSmallScreen ? 30 : 20 }}
                    onClick={handleLegendClick}
                    formatter={(value) => (
                        <span
                            style={{
                                color: disabledBars[value] ? '#888' : textColor,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                />
                {allBarNames.map((key) => (
                    <Bar
                        key={key}
                        dataKey={key}
                        fill={uniqueColors[key]}
                        name={key}
                        hide={disabledBars[key]}
                    >
                        {showLabels && (
                            <LabelList
                                dataKey={key}
                                position="top"
                                fill={textColor}
                                fontSize={isSmallScreen ? 10 : 12}
                                formatter={(value) => (value > 0 ? value.toLocaleString() : '')}
                            />
                        )}
                    </Bar>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default MonthlyBarChart;