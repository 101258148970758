import React from 'react';
import { Box, useTheme } from '@mui/material';
import YearlyBarChart from '../../charts/barCharts/YearlyChart';
import YearlyLineChart from '../../charts/lineCharts/YearlyChart';
import YearlyPieChart from '../../charts/pieCharts/YearlyChart';
import Loading from '../../components/Loading';

const YearlyContent = ({ selectedSensor, showLabels, processedData, chartIsLoading, error, isSmallScreen, tabValue }) => {

    const theme = useTheme();

    if (!selectedSensor) {
        return <Loading />;
    }

    return (
        <Box display="flex" flexDirection="column" width="100%">
            <Box
                height="65vh"
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: isSmallScreen ? '1rem' : '0rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <YearlyBarChart
                    selectedMac={selectedSensor}
                    showLabels={showLabels}
                    isSmallScreen={isSmallScreen}
                    processedData={processedData}
                    chartIsLoading={chartIsLoading}
                    error={error}
                    tabValue={tabValue}
                />
            </Box>
            <Box
                height="65vh"
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: isSmallScreen ? '1rem' : '1rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <YearlyLineChart
                    selectedMac={selectedSensor}
                    showLabels={showLabels}
                    isSmallScreen={isSmallScreen}
                    processedData={processedData}
                    chartIsLoading={chartIsLoading}
                    error={error}
                    tabValue={tabValue}
                />
            </Box>
            <Box
                height={isSmallScreen ? "50vh" : "65vh"}
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: '1rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <YearlyPieChart
                    selectedMac={selectedSensor}
                    showLabels={showLabels}
                    isSmallScreen={isSmallScreen}
                    processedData={processedData}
                    chartIsLoading={chartIsLoading}
                    error={error}
                    tabValue={tabValue}
                />
            </Box>
        </Box>
    );
};

export default YearlyContent;
