import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { CUSTOM_COLORS } from '../components/gradientDefs';

const DashboardYearlyChart = ({ isLoading, processedData }) => {
    const themeMode = useSelector(({ global }) => global.mode);
    const [disabledBars, setDisabledBars] = useState({});

    if (isLoading || !processedData || Object.keys(processedData).length === 0) {
        return (
            <div style={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loading />
            </div>
        );
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    // Get the last 5 years
    const lastFiveYears = Array.from({ length: 5 }, (_, index) => {
        const currentYear = new Date().getFullYear();
        return (currentYear - index).toString();
    });

    // Transform data into Recharts format
    const chartData = lastFiveYears.map(year => {
        const yearData = processedData[year] || {};
        return {
            year,
            ...yearData
        };
    });

    // Get keys (categories) from the first year's data
    const firstYearData = processedData[lastFiveYears[0]] || {};
    const keys = Object.keys(firstYearData).filter(key => key !== 'total');

    const handleLegendClick = (e) => {
        const clickedBar = e.dataKey;
        setDisabledBars((prev) => ({
            ...prev,
            [clickedBar]: !prev[clickedBar],
        }));
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={chartData}
                margin={{ top: 40, right: 30, bottom: 60, left: 60 }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis
                    dataKey="year"
                    tick={{ fill: textColor }}
                />
                <YAxis
                    tick={{ fill: textColor }}
                    width={30}
                />
                <Tooltip
                    contentStyle={{
                        backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#333333',
                        border: 'none',
                        borderRadius: '8px',
                        color: textColor,
                    }}
                    cursor={{ fill: 'grey', opacity: 0.1 }}
                />
                <Legend
                    align="center"
                    wrapperStyle={{ color: textColor }}
                    onClick={handleLegendClick}
                    formatter={(value) => (
                        <span
                            style={{
                                color: disabledBars[value] ? '#888' : textColor,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                />
                {keys.map((key, index) => (
                    <Bar
                        key={key}
                        dataKey={key}
                        fill={CUSTOM_COLORS[index % CUSTOM_COLORS.length]}
                        radius={[4, 4, 0, 0]}
                        hide={disabledBars[key]}
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default DashboardYearlyChart;