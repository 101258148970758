import React, { useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { CUSTOM_COLORS } from '../components/gradientDefs';
import { useTheme, useMediaQuery } from '@mui/material';
import { getISOWeek, getISOWeekYear } from 'date-fns';

const WeeklyChart = ({ weeklyData, weeklyLoading, selectedMac, selectedMonth, selectedYear, error, showLabels }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const themeMode = useSelector(({ global }) => global.mode);
    const [disabledBars, setDisabledBars] = useState({});

    // Generate all weeks within the selected month and year
    const chartData = useMemo(() => {
        if (!weeklyData || weeklyLoading) return [];

        const startDate = new Date(selectedYear, selectedMonth - 1, 1);
        const endDate = new Date(selectedYear, selectedMonth, 0);

        const startISOWeek = getISOWeek(startDate);
        const startISOYear = getISOWeekYear(startDate);
        const endISOWeek = getISOWeek(endDate);
        const endISOYear = getISOWeekYear(endDate);

        const yearData = weeklyData[selectedMac]?.[selectedYear];
        if (!yearData) return [];

        const chartData = [];
        let currentWeek = startISOWeek;
        let currentYear = startISOYear;

        while (currentYear < endISOYear || (currentYear === endISOYear && currentWeek <= endISOWeek)) {
            const weekData = yearData[selectedMonth]?.[currentWeek];
            if (weekData) {
                const { Total, ...filteredWeekData } = weekData;
                chartData.push({ week: currentWeek, ...filteredWeekData });
            }

            // Move to the next week
            currentWeek++;
            if (currentWeek > 52) {
                currentWeek = 1;
                currentYear++;
            }
        }

        return chartData;
    }, [weeklyData, weeklyLoading, selectedMac, selectedYear, selectedMonth]);

    // Get all unique bar names from the weekly data, excluding 'Total'
    const allBarNames = useMemo(() => {
        const barNames = new Set();
        if (weeklyData && weeklyData[selectedMac]) {
            Object.values(weeklyData[selectedMac]).forEach((yearData) => {
                Object.values(yearData).forEach((monthData) => {
                    Object.values(monthData).forEach((weekData) => {
                        Object.keys(weekData).forEach((key) => {
                            if (key !== 'Total') {
                                barNames.add(key);
                            }
                        });
                    });
                });
            });
        }
        return Array.from(barNames).sort((a, b) => {
            const aNum = parseFloat(a);
            const bNum = parseFloat(b);
            if (!isNaN(aNum) && !isNaN(bNum)) {
                return aNum - bNum;
            }
            return a.localeCompare(b);
        });
    }, [weeklyData, selectedMac]);

    // Check if all values are zero
    const allDataZero = useMemo(() => {
        return chartData.every((data) =>
            allBarNames.every((key) => data[key] === 0)
        );
    }, [chartData, allBarNames]);

    // Shuffle colors for consistent assignment
    const shuffleColors = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const uniqueColors = useMemo(() => {
        const shuffledColors = shuffleColors([...CUSTOM_COLORS]);
        return allBarNames.reduce((acc, barName, index) => {
            acc[barName] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});
    }, [allBarNames]);

    // Calculate dynamic interval for XAxis ticks based on the number of weeks
    const calculateInterval = () => {
        const dataLength = chartData.length;
        if (dataLength <= 4) return 0;
        if (dataLength <= 8) return 1;
        return Math.floor(dataLength / 5);
    };

    const interval = calculateInterval();

    // Check for 404 error
    if (error && error.status === 404) {
        return <NoDataAvailableMessage report={true} />;
    }

    if (weeklyLoading || !weeklyData) {
        return <Loading report={true} />;
    }

    if (chartData.length === 0 || allDataZero) {
        return <NoDataAvailableMessage report={true} />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const handleLegendClick = (legendItem) => {
        const clickedBar = legendItem.dataKey;
        setDisabledBars((prev) => ({
            ...prev,
            [clickedBar]: !prev[clickedBar],
        }));
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={chartData}
                margin={isSmallScreen ? { top: 20, right: 10, bottom: 50, left: 20 } : { top: 20, right: 30, bottom: 60, left: 20 }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis
                    dataKey="week"
                    angle={isSmallScreen ? -50 : 0}
                    textAnchor={isSmallScreen ? 'end' : 'middle'}
                    interval={interval}
                    tick={{ fill: textColor, fontSize: isSmallScreen ? 10 : 12 }}
                    tickFormatter={(value) => `Week ${value}`}
                />
                <YAxis
                    tick={{ fill: textColor }}
                    width={30}
                />
                <Tooltip
                    contentStyle={{
                        backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#333333',
                        border: 'none',
                        borderRadius: '8px',
                        color: textColor,
                    }}
                    cursor={{ fill: 'grey', opacity: 0.1 }}
                />
                <Legend
                    align="center"
                    wrapperStyle={{ color: textColor, paddingTop: isSmallScreen ? 30 : 10 }}
                    onClick={handleLegendClick}
                    formatter={(value) => (
                        <span
                            style={{
                                color: disabledBars[value] ? '#888' : textColor,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                />
                {allBarNames.map((key) => (
                    <Bar
                        key={key}
                        dataKey={key}
                        fill={uniqueColors[key]}
                        name={key}
                        hide={disabledBars[key]}
                    >
                        {showLabels && (
                            <LabelList
                                dataKey={key}
                                position="top"
                                fill={textColor}
                                fontSize={isSmallScreen ? 10 : 12}
                                formatter={(value) => (value > 0 ? value.toLocaleString() : '')}
                            />
                        )}
                    </Bar>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default WeeklyChart;