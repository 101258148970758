import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Grid, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetUserQuery, useUpdateUserMutation, useChangePasswordMutation } from "../../services/api";
import ErrorSnackbar from '../../components/ErrorSnackbar';
import SettingsHeader from './SettingsHeader';

const Settings = () => {
    const theme = useTheme();

    const userId = useSelector(state => state.global.id);
    const { data: userData } = useGetUserQuery(userId);
    const [updateProfile] = useUpdateUserMutation();
    const [changePassword] = useChangePasswordMutation();

    const [userDetails, setUserDetails] = useState({
        name: '',
        email: '',
        company: '',
        country: '',
        phoneNumber: '',
    });

    const [passwordDetails, setPasswordDetails] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

    useEffect(() => {
        if (userData && userData.user && Object.keys(userData.user).length > 0) {
            setUserDetails({
                name: userData.user.name || '',
                email: userData.user.email || '',
                company: {
                    name: userData.user.company.name || '',
                    _id: userData.user.company._id || '',
                },
                country: userData.user.country || '',
                phoneNumber: userData.user.phoneNumber || '',
            });
        }
    }, [userData]);

    const handleInputChange = (e, field) => {
        setUserDetails({ ...userDetails, [field]: e.target.value });
    };

    const handlePasswordChange = (e, field) => {
        setPasswordDetails({ ...passwordDetails, [field]: e.target.value });
    };

    const handleUpdateProfile = async () => {
        try {
            const payload = {
                ...userDetails,
                id: userId,
                company: userDetails.company._id,
            };

            const { error } = await updateProfile(payload);

            if (error) {
                setSnackbar({
                    open: true,
                    message: 'Error updating profile',
                    severity: 'error',
                });
                console.error('Error updating profile:', error);
                return;
            }

            setSnackbar({
                open: true,
                message: 'Profile updated successfully',
                severity: 'success',
            });
            console.log('Profile updated successfully:');
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Error updating profile',
                severity: 'error',
            });
            console.error('Error updating profile:', error);
        }
    };

    const handleChangePassword = async () => {
        try {
            const { oldPassword, newPassword, confirmPassword } = passwordDetails;

            if (!oldPassword || !newPassword || !confirmPassword) {
                setSnackbar({
                    open: true,
                    message: 'Please fill in all password fields',
                    severity: 'error',
                });
                return;
            }

            if (newPassword !== confirmPassword) {
                setSnackbar({
                    open: true,
                    message: 'New password and confirm password do not match',
                    severity: 'error',
                });
                return;
            }

            const { error } = await changePassword({
                userId,
                oldPassword,
                newPassword,
            });

            if (error) {
                setSnackbar({
                    open: true,
                    message: 'Invalid Old Password',
                    severity: 'error',
                });
                console.error('Error changing password:', error);
                return;
            }

            setSnackbar({
                open: true,
                message: 'Password changed successfully',
                severity: 'success',
            });
            console.log('Password changed successfully:');
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Error changing password',
                severity: 'error',
            });
            console.error('Error changing password:', error);
        }
    };

    const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

    return (
        <Box mx="auto" width="100%" p={4}>
            <SettingsHeader />
            <Box
                p={4}
                borderRadius={5}
                mb={3}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.secondary.main,
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography variant="h3" sx={{ fontSize: '2rem', fontWeight: 'bold', color: theme.palette.text.white, mb: '1rem' }}>
                    Edit My Details
                </Typography>
                <Grid container spacing={2}>
                    {Object.entries(userDetails).map(([key, value]) => (
                        <Grid item xs={12} md={6} key={key}>
                            <Typography sx={{ color: theme.palette.text.white }}>{key.charAt(0).toUpperCase() + key.slice(1)}</Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={key === 'company' ? value.name : value}
                                onChange={(e) => handleInputChange(e, key)}
                                disabled={key === 'company'}
                            />
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            gap={3}
                            mt={2}
                        >
                            <Button
                                onClick={handleUpdateProfile}
                                variant="contained"
                                sx={{
                                    mt: '10px',
                                    color: theme.palette.text.black,
                                    backgroundColor: theme.palette.text.white,
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        backgroundColor: theme.palette.text.white,
                                    },
                                    '&:focus': {
                                        outline: 'none',
                                    }
                                }}
                            >
                                Update Profile
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box
                p={4}
                borderRadius={5}
                mb={3}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.secondary.main,
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography variant="h3" sx={{ fontSize: '2rem', fontWeight: 'bold', color: theme.palette.text.white, mb: '1rem' }}>
                    Change Password
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography sx={{ color: theme.palette.text.white }}>Old Password</Typography>
                        <TextField
                            type="password"
                            variant="outlined"
                            fullWidth
                            value={passwordDetails.oldPassword}
                            onChange={(e) => handlePasswordChange(e, 'oldPassword')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography sx={{ color: theme.palette.text.white }}>New Password</Typography>
                        <TextField
                            type="password"
                            variant="outlined"
                            fullWidth
                            value={passwordDetails.newPassword}
                            onChange={(e) => handlePasswordChange(e, 'newPassword')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography sx={{ color: theme.palette.text.white }}>Confirm Password</Typography>
                        <TextField
                            type="password"
                            variant="outlined"
                            fullWidth
                            value={passwordDetails.confirmPassword}
                            onChange={(e) => handlePasswordChange(e, 'confirmPassword')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            gap={3}
                            mt={2}
                        >
                            <Button
                                onClick={handleChangePassword}
                                variant="contained"
                                sx={{
                                    mt: '10px',
                                    color: theme.palette.text.black,
                                    backgroundColor: theme.palette.text.white,
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        backgroundColor: theme.palette.text.white,
                                    },
                                    '&:focus': {
                                        outline: 'none',
                                    }
                                }}
                            >
                                Change Password
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <ErrorSnackbar
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Box>
    );
};

export default Settings;