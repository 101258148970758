import React from 'react';
import { Box, useTheme } from '@mui/material';
import OverviewChart from '../../charts/barCharts/OverviewChart';
import Loading from '../../components/Loading';

const OverviewContent = ({ selectedSensor, overviewData, overviewLoading, error, isSmallScreen, tabValue }) => {
    const theme = useTheme();

    return (
        <Box
            height="65vh"
            sx={{
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                borderRadius: "15px",
                backgroundColor: theme.palette.background.paper,
                mt: '1rem',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {selectedSensor ? (
                <Box
                    sx={{
                        flex: 1,
                        minHeight: 0,
                    }}
                >
                    <OverviewChart
                        selectedMac={selectedSensor}
                        overviewData={overviewData}
                        overviewLoading={overviewLoading}
                        error={error}
                        tabValue={tabValue}
                    />
                </Box>
            ) : (
                <Loading />
            )}
        </Box>
    );
};

export default OverviewContent;