import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, useTheme, Button } from '@mui/material';
import { APIProvider, Map, AdvancedMarker } from '@vis.gl/react-google-maps';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const SiteBox = ({ title, location, devicesConnected, latitude, longitude, siteId, enabled, onStatusChange, onRemove, isSmallScreen }) => {
    const theme = useTheme();
    const [status, setStatus] = useState(enabled ? 'enabled' : 'disabled');
    const { role } = useSelector((state) => state.global);
    const isAdmin = role === 'Admin';

    const handleStatusChange = (event, newStatus) => {
        setStatus(newStatus);
        onStatusChange(siteId, newStatus);
    };

    const handleDeleteClick = () => {
        onRemove();
    };

    // Ensure latitude and longitude are valid numbers
    const lat = typeof latitude === 'number' ? latitude : parseFloat(latitude);
    const lng = typeof longitude === 'number' ? longitude : parseFloat(longitude);

    // Create the location object for Google Maps
    const locationObj = {
        lat: !isNaN(lat) ? lat : 0,
        lng: !isNaN(lng) ? lng : 0,
    };

    return (
        <Box
            position="relative"
            backgroundColor={theme.palette.background.paper}
            p="1rem"
            borderRadius="0.55rem"
            mb="20px"
            sx={{
                overflow: 'hidden',
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            {/* Google Maps Section */}
            <APIProvider apiKey="AIzaSyAHJh02Ky1BfcvzBWPOew7ZLytA-1HY1Hc">
                <Map
                    style={{ width: '100%', height: '300px', marginBottom: '20px' }}
                    defaultCenter={locationObj}
                    defaultZoom={15}
                    mapId="DEMO_MAP_ID"
                    gestureHandling="greedy"
                    disableDefaultUI={true}
                >
                    <AdvancedMarker position={locationObj} />
                </Map>
            </APIProvider>

            {/* Site Details Section */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px="10px"
            >
                <Typography variant={isSmallScreen ? "h3" : "h2"} color={theme.palette.text.white} fontWeight="bold" mb="15px">
                    {title}
                </Typography>
                {isAdmin && (
                    <ToggleButtonGroup
                        value={status}
                        exclusive
                        onChange={handleStatusChange}
                        size="small"
                    >
                        <ToggleButton
                            value="enabled"
                            sx={{
                                '&:focus': {
                                    outline: 'none',
                                },
                            }}
                        >
                            Enabled
                        </ToggleButton>
                        <ToggleButton
                            value="disabled"
                            sx={{ '&:focus': { outline: 'none' } }}
                        >
                            Disabled
                        </ToggleButton>
                    </ToggleButtonGroup>
                )}
            </Box>

            {/* Remove Site Button (Admin Only) */}
            {isAdmin && (
                <Button
                    onClick={handleDeleteClick}
                    variant="contained"
                    color="error"
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        mr: '25px',
                        mb: '25px',
                        '&:focus': { outline: 'none' },
                    }}
                >
                    Remove Site
                </Button>
            )}

            {/* Location and Devices Connected */}
            <Typography variant={isSmallScreen ? "h5" : "h4"} sx={{ mb: "10px", px: "10px" }}>
                Location: {location}
            </Typography>
            <Typography variant={isSmallScreen ? "h6" : "h5"} sx={{ mb: "10px", px: "10px" }}>
                Devices Connected: {devicesConnected}
            </Typography>
        </Box>
    );
};

export default SiteBox;