import React, { useState } from 'react';
import { Box, Typography, useTheme, Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, MenuItem, Slide } from '@mui/material';
import userIcon from '../assets/user.png';
import { useDeactivateUserMutation, useActivateUserMutation, useRemoveUserMutation, useEditUserMutation } from '../services/api';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserBox = ({ user, showSnackbar, refetchUsers }) => {
    const theme = useTheme();
    const [showButtons, setShowButtons] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [editedUserData, setEditedUserData] = useState({ ...user });
    const [deactivateUserMutation] = useDeactivateUserMutation();
    const [activateUserMutation] = useActivateUserMutation();
    const [removeUserMutation] = useRemoveUserMutation();
    const [editUserMutation] = useEditUserMutation();

    const handleBoxClick = (event) => {
        if (event.target === event.currentTarget) {
            setShowButtons(!showButtons);
        }
    };

    const handleEditUser = () => {
        setOpenDialog(true);
    };

    const handleRemoveUser = async () => {
        try {
            await removeUserMutation(user._id);
            showSnackbar('User removed successfully', 'success');
            refetchUsers();
        } catch (error) {
            console.error('Error removing user:', error);
        }
    };
    
    const handleActivateUser = async () => {
        try {
            await activateUserMutation(user._id);
            showSnackbar('User activated successfully', 'success');
            refetchUsers();
        } catch (error) {
            console.error('Error activating user:', error);
            showSnackbar('Error updating user', 'error');
        }
    };

    const handleDeactivateUser = async () => {
        try {
            await deactivateUserMutation(user._id);
            showSnackbar('User deactivated successfully', 'success');
            refetchUsers();
        } catch (error) {
            console.error('Error deactivating user:', error);
            showSnackbar('Error updating user', 'error');
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEditedUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSaveChanges = async () => {
        try {
            await editUserMutation({ userId: user._id, data: editedUserData });

            showSnackbar('User updated successfully', 'success');
            refetchUsers();
            setOpenDialog(false);
        } catch (error) {
            console.error('Error updating user:', error);
            showSnackbar('Error updating user', 'error');
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p="2rem 1rem"
            backgroundColor={theme.palette.background.paper}
            borderRadius="1.5rem"
            height="375px"
            width="275px"
            boxShadow={`5px 4px 15px rgba(0, 0, 0, 0.1)`}
            onClick={handleBoxClick}
        >
            {showButtons ? (
                <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                    <Button
                        variant="contained"
                        onClick={handleEditUser}
                        sx={buttonStyles(theme)}
                    >
                        Edit Role
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleActivateUser}
                        sx={buttonStyles(theme)}
                    >
                        Activate User
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleDeactivateUser}
                        sx={buttonStyles(theme)}
                    >
                        Deactivate User
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleRemoveUser}
                        sx={buttonStyles(theme)}
                    >
                        Remove User
                    </Button>
                </Box>
            ) : (
                <>
                    <img src={userIcon} alt="Icon" style={{ width: '100px', height: '100px' }} />
    
                    <Divider sx={{ marginY: '1rem', width: '100%', borderTop: `3px solid ${theme.palette.text.white}` }} />
    
                    <Typography variant="h3" sx={{ marginBottom: '10px', fontWeight: 'bold', textAlign: 'center' }}>
                        {user.name}
                    </Typography>
    
                    <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center', hyphens: 'auto' }}>
                        {user.company.name}
                    </Typography>
    
                    <Divider sx={{ marginY: '1rem', width: '100%', borderTop: `3px solid ${theme.palette.text.white}` }} />
    
                    <Typography variant="h4" sx={{ marginBottom: '5px', fontWeight: 'bold', textAlign: 'center' }}>
                        {user.role.name}
                    </Typography>
    
                    <Box display="flex" alignItems="center" mt="5px">
                        <Box
                            width="10px"
                            height="10px"
                            borderRadius="50%"
                            backgroundColor={user.active ? 'green' : 'red'}
                            mr="10px"
                        />
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            {user.active ? 'Activated' : 'Deactivated'}
                        </Typography>
                    </Box>
                </>
            )}
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                onClose={handleCloseDialog}
                aria-describedby="add-user-dialog-description"
                PaperProps={{ sx: { backgroundColor: theme.palette.background.paper } }}
            >
                <DialogTitle variant="h2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                    Edit Role
                </DialogTitle>
                <DialogContent sx={{ width: '500px', padding: '20px' }}>
                    <Box display="flex" flexDirection="column" gap={2} mt={2}>
                        <FormControl fullWidth>
                            <InputLabel id="role-label" sx={{ color: theme.palette.secondary.main }}>
                                Role
                            </InputLabel>
                            <Select
                                labelId="role-label"
                                id="role"
                                name="role"
                                label="Role"
                                value={editedUserData.role || ''}
                                onChange={handleInputChange}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <MenuItem value="Admin">Admin</MenuItem>
                                <MenuItem value="User">User</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ padding: '16px 24px', justifyContent: 'center' }}>
                    <Button
                        onClick={handleCloseDialog}
                        variant="contained"
                        sx={{
                            color: theme.palette.text.black,
                            backgroundColor: theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.main,
                            },
                            '&:focus': {
                                outline: 'none',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveChanges}
                        variant="contained"
                        sx={{
                            color: theme.palette.text.black,
                            backgroundColor: theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.main,
                            },
                            '&:focus': {
                                outline: 'none',
                            },
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const buttonStyles = (theme) => ({
    backgroundColor: theme.palette.text.white,
    color: theme.palette.text.black,
    fontSize: "12px",
    minWidth: "200px",
    padding: "10px",
    margin: "10px",
    borderRadius: "15px",
    '&:hover': {
        backgroundColor: theme.palette.text.white,
    },
    '&:focus': {
        outline: 'none',
    }
});

export default UserBox;