import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';

const DashboardOverviewChart = ({ overviewData, overviewLoading }) => {
    const theme = useTheme();
    const themeMode = useSelector(({ global }) => global.mode);
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!overviewLoading && overviewData) {
            const transformedData = Object.entries(overviewData)
                .sort((a, b) => a[0] - b[0])
                .map(([year, monthlyData]) => {
                    return Object.entries(monthlyData)
                        .sort((a, b) => a[0] - b[0])
                        .map(([month, counts]) => {
                            const date = new Date(`${year}-${month}-01`);
                            const formattedMonth = date.toLocaleString('default', { month: 'short' }).toUpperCase();
                            const shortYear = year.slice(-2);
                            const totalCounts = Object.values(counts).reduce((acc, count) => acc + count, 0);
                            return {
                                x: `${formattedMonth}-${shortYear}`,
                                y: totalCounts,
                            };
                        });
                }).flat();

            setChartData(transformedData);
            setIsLoading(false);
        }
    }, [overviewData, overviewLoading]);

    if (isLoading || chartData.length === 0) {
        return (
            <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loading />
            </div>
        );
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    // Calculate the interval for XAxis labels
    const xAxisLabels = chartData.map(point => point.x);
    const maxLabels = 10;
    const labelInterval = Math.ceil(xAxisLabels.length / maxLabels);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                data={chartData}
                margin={{ top: 30, right: 30, bottom: 30, left: 40 }}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="20%" stopColor="#009DFF" stopOpacity={0.5} />
                        <stop offset="90%" stopColor="#0095FF" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <XAxis
                    dataKey="x"
                    angle={-40}
                    textAnchor="end"
                    interval={labelInterval - 1}
                    tick={{ fill: textColor }}
                />
                <YAxis
                    tick={{ fill: textColor }}
                    width={30}
                />
                <Tooltip
                    contentStyle={{
                        backgroundColor: theme.palette.background.default,
                        border: 'none',
                        borderRadius: '8px',
                        color: theme.palette.text.primary,
                    }}
                    formatter={(value) => [`Count: ${value}`]}
                />
                <Area
                    type="monotone"
                    dataKey="y"
                    stroke="#007DD6"
                    strokeWidth={2}
                    fill="url(#colorUv)"
                    activeDot={{ r: 8 }}
                    dot={{ r: 4, fill: '#007DD6' }}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default DashboardOverviewChart;