import React from 'react';
import { Box, Typography, Tabs, Tab, useTheme, MenuItem, Select, useMediaQuery } from '@mui/material';

const OverviewLabel = ({ sensorLabel, tabValue, onTabChange, addonData }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const handleTabChange = (event, newValue) => {
        onTabChange(newValue);
    };

    const handleDropdownChange = (event) => {
        onTabChange(parseInt(event.target.value, 10));
    };

    // Determine available tabs based on addon data
    const availableTabs = [];
    availableTabs.push({ label: "TOTAL", value: 0 });
    if (addonData?.[0]?.adultnchild) availableTabs.push({ label: "ADULT & CHILD", value: 1 });
    if (addonData?.[0]?.gender) availableTabs.push({ label: "GENDER", value: 2 });
    if (addonData?.[0]?.mask) availableTabs.push({ label: "MASK", value: 3 });

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={isMobile ? '0rem' : '1rem'}
            p={isMobile ? '0' : '1rem'}
            backgroundColor={theme.palette.background.paper}
            borderRadius="15px"
            sx={{
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography
                variant={isMobile ? "h3" : "h2"}
                component="div"
                sx={{
                    color: theme.palette.text.white,
                    fontWeight: "bold",
                    textAlign: "left",
                    ml: isMobile ? '0.5rem' : '0',
                }}
            >
                {sensorLabel}
            </Typography>
            {isMobile || isTablet ? (
                <Select
                    value={tabValue}
                    onChange={handleDropdownChange}
                    variant="outlined"
                    sx={{
                        fontSize: isMobile ? 'h6.fontSize' : 'h4.fontSize',
                        fontWeight: 'bold',
                        color: theme.palette.text.white,
                        height: '2.5rem',
                        margin: '0.5rem'
                    }}
                >
                    {availableTabs.map(tab => (
                        <MenuItem key={tab.value} value={tab.value}>
                            {tab.label}
                        </MenuItem>
                    ))}
                </Select>
            ) : (
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="secondary"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: theme.palette.text.white,
                        },
                    }}
                >
                    {availableTabs.map(tab => (
                        <Tab
                            key={tab.value}
                            label={tab.label}
                            sx={{
                                typography: isMobile ? 'h6' : 'h4',
                                fontWeight: 'bold',
                                color: tabValue === tab.value ? theme.palette.text.white : theme.palette.text.grey,
                                '&.Mui-selected': {
                                    color: theme.palette.text.white,
                                },
                                '&:focus': {
                                    outline: 'none',
                                },
                            }}
                        />
                    ))}
                </Tabs>
            )}
        </Box>
    );
};

export default OverviewLabel;
