import React, { useState, useEffect } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { useTotalDataQuery } from "../../services/api";
import DashboardHeader from './DashboardHeader';
import StatBoxes from './StatBoxes';
import OverviewChart from './OverviewChart';
import YearlyChart from './YearlyChart';
import TotalCountPie from './TotalCountPie';
import MapComponent from './MapComponent';
import { useSelector } from 'react-redux';

const Dashboard = () => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const company = useSelector(state => state.global.company);
    const { data: totalCountData, isLoading } = useTotalDataQuery(company);

    const [totalDevicesConnected, setTotalDevicesConnected] = useState(0);
    const [totalSites, setTotalSites] = useState(0);
    const [totalHumanCount, setTotalHumanCount] = useState(0);
    const [devicesOffline, setDevicesOffline] = useState(0);
    const [totalData, setTotalData] = useState({});
    const [yearlyData, setYearlyData] = useState({});
    const [monthlyData, setMonthlyData] = useState({});

    useEffect(() => {
        if (totalCountData) {
            setTotalDevicesConnected(totalCountData.deviceCount);
            setTotalSites(totalCountData.siteCount);
            setDevicesOffline(totalCountData.offlineDeviceCount);
            setTotalData(totalCountData.totalData);
            setYearlyData(totalCountData.yearlyData);
            setMonthlyData(totalCountData.monthlyData);
            const totalHumanCount = Object.values(totalCountData.totalData).reduce((acc, curr) => acc + curr, 0);
            setTotalHumanCount(totalHumanCount);
        }
    }, [totalCountData]);

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <DashboardHeader />
            {isTablet ? (
                <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 2fr)"
                    gridAutoRows="160px"
                    gap="20px"
                >
                    <StatBoxes 
                        totalDevicesConnected={totalDevicesConnected} 
                        totalSites={totalSites} 
                        devicesOffline={devicesOffline} 
                        totalHumanCount={totalHumanCount} 
                    />
                    <OverviewChart data={monthlyData} isLoading={isLoading} />
                    <MapComponent />
                    <TotalCountPie data={totalData} isLoading={isLoading} />
                    <YearlyChart data={yearlyData} isLoading={isLoading} />
                    <TotalCountPie data={totalData} isLoading={isLoading} />
                </Box>
            ) : (
                <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 2fr)"
                    gridAutoRows="160px"
                    gap="20px"
                >
                    <StatBoxes 
                        totalDevicesConnected={totalDevicesConnected} 
                        totalSites={totalSites} 
                        devicesOffline={devicesOffline} 
                        totalHumanCount={totalHumanCount} 
                    />
                    <MapComponent />
                    <OverviewChart data={monthlyData} isLoading={isLoading} />
                    <TotalCountPie data={totalData} isLoading={isLoading} />
                    <TotalCountPie data={totalData} isLoading={isLoading} />
                    <YearlyChart data={yearlyData} isLoading={isLoading} />
                </Box>  
            )}
        </Box>
    );
};

export default Dashboard;
