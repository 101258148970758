import React from 'react';
import { Box, useTheme } from '@mui/material';
import HourlyBarChart from '../../charts/barCharts/HourlyChart';
import HourlyLineChart from '../../charts/lineCharts/HourlyChart';
import HourlyPieChart from '../../charts/pieCharts/HourlyChart';
import Loading from '../../components/Loading';

const HourlyContent = ({ selectedSensor, processedData, chartIsLoading, error, isSmallScreen, showLabels }) => {

    const theme = useTheme();

    if (!selectedSensor) {
        return <Loading />;
    }

    return (
        <Box display="flex" flexDirection="column" width="100%">
            <Box
                height="65vh"
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: isSmallScreen ? '1rem' : '0rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <HourlyBarChart
                    selectedMac={selectedSensor}
                    showLabels={showLabels}
                    processedData={processedData}
                    chartIsLoading={chartIsLoading}
                    error={error}
                />
            </Box>
            <Box
                height="65vh"
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: '1rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <HourlyLineChart
                    selectedMac={selectedSensor}
                    showLabels={showLabels}
                    processedData={processedData}
                    chartIsLoading={chartIsLoading}
                    error={error}
                />
            </Box>
            <Box
                height={isSmallScreen ? "50vh" : "65vh"}
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: '1rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <HourlyPieChart
                    selectedMac={selectedSensor}
                    processedData={processedData}
                    chartIsLoading={chartIsLoading}
                    error={error}
                />
            </Box>
        </Box>
    );
};

export default HourlyContent;
