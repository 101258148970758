import React, { useState, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { CUSTOM_COLORS } from '../components/gradientDefs';
import { useTheme, useMediaQuery } from '@mui/material';

const DailyBarChart = ({ selectedMac, selectedDateRange, showLabels, processedData, chartIsLoading, error }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const themeMode = useSelector(({ global }) => global.mode);
    const [disabledBars, setDisabledBars] = useState({});

    // Generate all dates within the selected date range
    const dateRange = selectedDateRange[0];
    const allDates = useMemo(() => {
        const dates = [];
        let currentDate = new Date(dateRange.startDate);
        while (currentDate <= dateRange.endDate) {
            dates.push(format(currentDate, 'yyyy-MM-dd'));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    }, [dateRange]);

    // Get all unique bar names from the processed data
    const allBarNames = useMemo(() => {
        const barNames = new Set();
        if (processedData && processedData[selectedMac]) {
            Object.values(processedData[selectedMac]).forEach((dayData) => {
                Object.keys(dayData).forEach((key) => barNames.add(key));
            });
        }
        return Array.from(barNames).sort((a, b) => {
            const aNum = parseFloat(a);
            const bNum = parseFloat(b);
            if (!isNaN(aNum) && !isNaN(bNum)) {
                return aNum - bNum;
            }
            return a.localeCompare(b);
        });
    }, [processedData, selectedMac]);

    // Generate chart data for each date
    const chartData = useMemo(() => {
        return allDates.map((date) => {
            const dateCounts = processedData?.[selectedMac]?.[date] || {};
            const zeroCounts = allBarNames.reduce((acc, key) => {
                acc[key] = 0;
                return acc;
            }, {});

            return {
                day: format(new Date(date), 'do MMM'),
                ...zeroCounts,
                ...dateCounts,
            };
        });
    }, [allDates, processedData, selectedMac, allBarNames]);

    // Check if all values are zero
    const allDataZero = useMemo(() => {
        return chartData.every((data) =>
            allBarNames.every((key) => data[key] === 0)
        );
    }, [chartData, allBarNames]);

    // Shuffle colors for consistent assignment
    const shuffleColors = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const uniqueColors = useMemo(() => {
        const shuffledColors = shuffleColors([...CUSTOM_COLORS]);
        return allBarNames.reduce((acc, barName, index) => {
            acc[barName] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});
    }, [allBarNames]);

    // Calculate dynamic interval for XAxis ticks based on the number of dates
    const calculateInterval = () => {
        const dataLength = chartData.length;
        if (dataLength <= 7) return 0;
        if (dataLength <= 14) return 1;
        if (dataLength <= 30) return 2;
        return Math.floor(dataLength / 10);
    };

    const interval = calculateInterval();

    // Check for 404 error
    if (error && error.status === 404) {
        return <NoDataAvailableMessage />;
    }

    if (chartIsLoading || !processedData) {
        return <Loading />;
    }

    if (chartData.length === 0 || allDataZero) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const handleLegendClick = (legendItem) => {
        const clickedBar = legendItem.dataKey;
        setDisabledBars((prev) => ({
            ...prev,
            [clickedBar]: !prev[clickedBar],
        }));
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={chartData}
                margin={isSmallScreen ? { top: 30, right: 20, bottom: 20, left: 10 } : { top: 20, right: 30, bottom: 10, left: 10 }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis
                    dataKey="day"
                    angle={isSmallScreen ? -50 : 0}
                    textAnchor={isSmallScreen ? 'end' : 'middle'}
                    interval={interval}
                    tick={{ fill: textColor, fontSize: isSmallScreen ? 10 : 12 }}
                />
                <YAxis
                    tick={{ fill: textColor }}
                    width={30}
                />
                <Tooltip
                    contentStyle={{
                        backgroundColor: themeMode === 'light' ? '#FFFFFF' : '#333333',
                        border: 'none',
                        borderRadius: '8px',
                        color: textColor,
                    }}
                    cursor={{ fill: 'grey', opacity: 0.1 }}
                />
                <Legend
                    align="center"
                    wrapperStyle={{ color: textColor, paddingTop: isSmallScreen ? 30 : 20 }}
                    onClick={handleLegendClick}
                    formatter={(value) => (
                        <span
                            style={{
                                color: disabledBars[value] ? '#888' : textColor,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                />
                {allBarNames.map((key) => (
                    <Bar
                        key={key}
                        dataKey={key}
                        fill={uniqueColors[key]}
                        name={key}
                        hide={disabledBars[key]}
                    >
                        {showLabels && (
                            <LabelList
                                dataKey={key}
                                position="top"
                                fill={textColor}
                                fontSize={isSmallScreen ? 10 : 12}
                                formatter={(value) => (value > 0 ? value.toLocaleString() : '')}
                            />
                        )}
                    </Bar>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default DailyBarChart;