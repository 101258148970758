import React from 'react';
import Header from '../../components/Header';
import { useSelector } from 'react-redux';
import { Box, useTheme, useMediaQuery } from '@mui/material';

const DashboardHeader = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const username = useSelector(state => state.global.username);

    return (
        <Box
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            justifyContent="space-between"
            marginBottom="1rem"
            padding="1rem"
            backgroundColor={theme.palette.background.paper}
            borderRadius='15px'
            sx={{
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Header title={`Hey, ${username}`} subtitle="Welcome to your dashboard"/>
        </Box>
    )
}

export default DashboardHeader