import React from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { AddBoxOutlined } from '@mui/icons-material';
import Header from '../../components/Header';

const UsersHeader = ({ role, handleOpenDialog, isSmallScreen }) => {
    const theme = useTheme();
    return (
        <Box
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            justifyContent="space-between"
            marginBottom="1rem"
            padding="1rem"
            backgroundColor={theme.palette.background.paper}
            borderRadius='15px'
            sx={{
                boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Header title="USERS" subtitle="See your users" />
            {role === 'Admin' && (
                <Button
                    onClick={handleOpenDialog}
                    sx={{
                        backgroundColor: theme.palette.text.white,
                        color: theme.palette.text.black,
                        padding: "0rem 1rem",
                        '&:hover': {
                            backgroundColor: theme.palette.text.white,
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                        m: '0.5rem'
                    }}
                >
                    <AddBoxOutlined sx={{ marginRight: "10px" }} />
                    Add Users
                </Button>
            )}
        </Box>
    );
};

export default UsersHeader;
