export const tokensDark = {
    black: '#000000',
    white: '#fff',
    grey: '#191919',
};

export const tokensLight = {
    black: '#000000',
    white: '#fff',
    grey: '#FAFBFC',
    grey2: '#828282',
};

export const themeSettings = (mode) => {
    return {
        palette: {
            mode: mode,
            ...(mode === "dark"
                ? {
                    text: {
                        white: tokensDark.white,
                        black: tokensDark.black,
                    },
                    background: {
                        default: tokensDark.black,
                        paper: tokensDark.grey,
                    },
                }
                : {
                    text: {
                        white: tokensLight.black,
                        black: tokensLight.white,
                        grey: tokensLight.grey2
                    },
                    background: {
                        default: tokensLight.grey,
                        paper: tokensLight.white,
                    },
                }),
        },
        typography: {
            fontFamily: ["Inter", "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 40,
            },
            h2: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 32,
            },
            h3: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 24,
            },
            h4: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 20,
            },
            h5: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 14,
            },
        },
    };
};
