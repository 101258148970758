import React from 'react';
import { Box, useTheme } from '@mui/material';
import WeeklyBarChart from '../../charts/barCharts/WeeklyChart';
import WeeklyLineChart from '../../charts/lineCharts/WeeklyChart';
import WeeklyPieChart from '../../charts/pieCharts/WeeklyChart';
import Loading from '../../components/Loading';

const WeeklyContent = ({ selectedSensor, showLabels, processedData, selectedMonth, selectedYear, error, isLoading, isSmallScreen, tabValue }) => {

    const theme = useTheme();

    if (!selectedSensor) {
        return <Loading />;
    }

    return (
        <Box display="flex" flexDirection="column" width="100%">
            <Box
                height="65vh"
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: isSmallScreen ? '1rem' : '0rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <WeeklyBarChart 
                    selectedMac={selectedSensor}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    showLabels={showLabels}
                    isSmallScreen={isSmallScreen}
                    processedData={processedData}
                    isLoading={isLoading}
                    error={error}
                    tabValue={tabValue}
                />
            </Box>
            <Box
                height="65vh"
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: '1rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <WeeklyLineChart 
                    selectedMac={selectedSensor}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    processedData={processedData}
                    isLoading={isLoading}
                    isSmallScreen={isSmallScreen}
                    showLabels={showLabels}
                    error={error}
                    tabValue={tabValue}
                />
            </Box>
            <Box
                height={isSmallScreen ? "50vh" : "65vh"}
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: '1rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <WeeklyPieChart
                    selectedMac={selectedSensor}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    processedData={processedData}
                    isLoading={isLoading}
                    isSmallScreen={isSmallScreen}
                    showLabels={showLabels}
                    tabValue={tabValue}
                />
            </Box>
        </Box>
    );
};

export default WeeklyContent;
