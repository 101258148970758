import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from '../services/api';
import rootReducer from './reducers';

const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['global'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST'],
            },
        }).concat(api.middleware),
});

// Enable RTK Query listeners
setupListeners(store.dispatch);

// Set up the persistor for the store
const persistor = persistStore(store);

export { store, persistor };
