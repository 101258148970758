import React from 'react';
import { Box, useTheme } from '@mui/material';
import MonthlyBarChart from '../../charts/barCharts/MonthlyChart';
import MonthlyLineChart from '../../charts/lineCharts/MonthlyChart';
import MonthlyPieChart from '../../charts/pieCharts/MonthlyChart';
import Loading from '../../components/Loading';

const MonthlyContent = ({ selectedSensor, selectedYearRange, showLabels, processedData, chartIsLoading, error, isSmallScreen, tabValue }) => {

    const theme = useTheme();

    if (!selectedSensor) {
        return <Loading />;
    }

    return (
        <Box display="flex" flexDirection="column" width="100%">
            <Box
                height="65vh"
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: isSmallScreen ? '1rem' : '0rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <MonthlyBarChart 
                    selectedSensor={selectedSensor}
                    selectedYear={selectedYearRange[0]}
                    showLabels={showLabels}
                    isSmallScreen={isSmallScreen}
                    processedData={processedData}
                    chartIsLoading={chartIsLoading}
                    error={error}
                    tabValue={tabValue}
                />
            </Box><Box
                height="65vh"
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: '1rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <MonthlyLineChart 
                    selectedSensor={selectedSensor}
                    selectedYear={selectedYearRange[0]}
                    showLabels={showLabels}
                    isSmallScreen={isSmallScreen}
                    processedData={processedData}
                    chartIsLoading={chartIsLoading}
                    error={error}
                    tabValue={tabValue}
                />
            </Box>
            <Box
                height={isSmallScreen ? "50vh" : "65vh"}
                sx={{
                    boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: "15px",
                    mt: '1rem',
                    p: isSmallScreen ? 1 : 2,
                    backgroundColor: theme.palette.background.paper
                }}
            >
                <MonthlyPieChart
                    selectedSensor={selectedSensor}
                    selectedYear={selectedYearRange[0]}
                    showLabels={showLabels}
                    isSmallScreen={isSmallScreen}
                    processedData={processedData}
                    chartIsLoading={chartIsLoading}
                    error={error}
                    tabValue={tabValue}
                />
            </Box>
        </Box>
    );
};

export default MonthlyContent;
