import React, { useState, useMemo } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { CUSTOM_COLORS } from '../components/gradientDefs';
import { useTheme } from '@mui/material';

const DashboardTotalPie = ({ data, isLoading }) => {
    const theme = useTheme();
    const [disabledLegends, setDisabledLegends] = useState([]);

    // Move useMemo to the top to ensure it is called unconditionally
    const pieData = useMemo(() => {
        if (!data) return [];
        return Object.entries(data).map(([key, value]) => ({
            name: key,
            value,
        }));
    }, [data]);

    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const uniqueColors = useMemo(() => {
        const shuffledColors = shuffleArray([...CUSTOM_COLORS]);
        return pieData.reduce((acc, entry, index) => {
            acc[entry.name] = shuffledColors[index % shuffledColors.length];
            return acc;
        }, {});
    }, [pieData]);

    if (isLoading) {
        return (
            <div style={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return <NoDataAvailableMessage />;
    }

    const handleLegendClick = (entry) => {
        const clickedLegend = entry.value;
        setDisabledLegends((prev) =>
            prev.includes(clickedLegend)
                ? prev.filter((item) => item !== clickedLegend)
                : [...prev, clickedLegend]
        );
    };

    const filteredData = pieData.filter((entry) => !disabledLegends.includes(entry.name));

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie
                    data={filteredData}
                    cx="50%"
                    cy="50%"
                    innerRadius="40%"
                    outerRadius="80%"
                    paddingAngle={5}
                    dataKey="value"
                    labelLine={false}
                    stroke="none"
                >
                    {pieData.map((entry, index) =>
                        !disabledLegends.includes(entry.name) ? (
                            <Cell
                                key={`cell-${entry.name}`}
                                fill={uniqueColors[entry.name]}
                            />
                        ) : null
                    )}
                </Pie>
                <Tooltip
                    contentStyle={{
                        border: 'none',
                        borderRadius: '8px',
                        color: 'white',
                    }}
                    formatter={(value, name) => {
                        const total = pieData.reduce((acc, entry) => acc + entry.value, 0);
                        const percentage = ((value / total) * 100).toFixed(2);
                        return [`${value} (${percentage}%)`, name];
                    }}
                />
                <Legend
                    wrapperStyle={{ color: theme.palette.text.black }}
                    onClick={handleLegendClick}
                    formatter={(value) => (
                        <span
                            style={{
                                color: disabledLegends.includes(value) ? '#888' : theme.palette.text.white,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                    payload={pieData.map((entry) => ({
                        value: entry.name,
                        type: 'circle',
                        id: entry.name,
                        color: disabledLegends.includes(entry.name)
                            ? '#888'
                            : uniqueColors[entry.name],
                    }))}
                    layout="horizontal"
                    align="center"
                    verticalAlign="bottom"
                />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default DashboardTotalPie;