import React, { useState, useCallback } from 'react';
import MonthlyHeader from './MonthlyHeader';
import useSelectedSensor from '../../hooks/useSelectedSensor';
import useSensorOptions from '../../hooks/useSensorOptions';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import MonthlyContent from './MonthlyContent';
import { Box, Grid, Paper, useTheme, useMediaQuery } from '@mui/material';
import { useMonthlyDataQuery, useAdultnChildMonthlyDataQuery, useGenderMonthlyDataQuery, useGetAddonByDeviceQuery } from '../../services/api';
import MonthlyFilterBox from './MonthlyFilterBox';
import MonthlyLabel from './MonthlyLabel';
import { useSelector } from 'react-redux';

const Monthly = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { company, role } = useSelector(state => state.global);

    const { companyOptions } = useCompanyOptions();
    const isSuperOrPowerAdmin = role === 'Super Admin' || role === 'Power Admin';
    const { selectedCompany, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const sensorOptions = useSensorOptions(isSuperOrPowerAdmin ? selectedCompany : company);
    const { selectedSensor, handleSensorChange, getSelectedSensorLabel } = useSelectedSensor(sensorOptions, isSuperOrPowerAdmin ? selectedCompany : company);

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedYearRange, setSelectedYearRange] = useState(getDefaultYearRange());
    const [showLabels, setShowLabels] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const { data: addonData } = useGetAddonByDeviceQuery(selectedSensor, {
        skip: !selectedSensor,
    });

    const availableTabs = [
        { label: "TOTAL", value: 0 },
        addonData?.[0]?.adultnchild && { label: "ADULT & CHILD", value: 1 },
        addonData?.[0]?.gender && { label: "GENDER", value: 2 },
        addonData?.[0]?.mask && { label: "MASK", value: 3 },
    ].filter(Boolean);

    const { data: monthlyData, isLoading: monthlyLoading, error: monthlyError } = useMonthlyDataQuery({ mac: selectedSensor }, { skip: tabValue !== 0 || !selectedSensor });
    const { data: adultChildData, isLoading: adultChildLoading, error: adultChildError } = useAdultnChildMonthlyDataQuery({ mac: selectedSensor }, { skip: tabValue !== 1 || !selectedSensor });
    const { data: genderData, isLoading: genderLoading, error: genderError } = useGenderMonthlyDataQuery({ mac: selectedSensor }, { skip: tabValue !== 2 || !selectedSensor });

    const processedData = availableTabs[tabValue]?.value === 0 ? monthlyData 
                        : availableTabs[tabValue]?.value === 1 ? adultChildData 
                        : availableTabs[tabValue]?.value === 2 ? genderData 
                        : null;

    const chartIsLoading = availableTabs[tabValue]?.value === 0 ? monthlyLoading 
                            : availableTabs[tabValue]?.value === 1 ? adultChildLoading 
                            : availableTabs[tabValue]?.value === 2 ? genderLoading 
                            : null;

    const error = availableTabs[tabValue]?.value === 0 ? monthlyError 
                : availableTabs[tabValue]?.value === 1 ? adultChildError 
                : availableTabs[tabValue]?.value === 2 ? genderError 
                : null;

    const handlePopoverOpen = useCallback((event) => setAnchorEl(event.currentTarget), []);
    const handlePopoverClose = useCallback(() => setAnchorEl(null), []);

    function getDefaultYearRange() {
        const currentYear = new Date().getFullYear();
        return [currentYear];
    }

    const handleShowLabelsChange = useCallback((event) => {
        setShowLabels(event.target.checked);
    }, []);

    const handleTabChange = (newValue) => {
        setTabValue(newValue);
    };

    // JSX
    return (
        <Box m={isSmallScreen ? "0.5rem" : "1rem 2.5rem"}>
            <Grid container spacing={2} paddingBottom={3}>
                <Grid item xs={12} md={10}>
                    <MonthlyHeader isSmallScreen={isSmallScreen} />
                    <MonthlyLabel
                        sensorLabel={getSelectedSensorLabel()}
                        isSmallScreen={isSmallScreen}
                        tabValue={tabValue}
                        onTabChange={handleTabChange}
                        addonData={addonData}
                    />
                    <MonthlyContent
                        selectedSensor={selectedSensor}
                        selectedYearRange={selectedYearRange}
                        showLabels={showLabels}
                        processedData={processedData}
                        chartIsLoading={chartIsLoading}
                        error={error}
                        isSmallScreen={isSmallScreen}
                        sensorLabel={getSelectedSensorLabel()}
                        tabValue={tabValue}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Paper sx={{ position: 'sticky', top: 20, height: 'auto', overflow: 'auto', borderRadius: '15px', boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)' }}>
                        <MonthlyFilterBox
                            sensorLabel={getSelectedSensorLabel()}
                            isSmallScreen={isSmallScreen}
                            showLabels={showLabels}
                            onShowLabelsChange={handleShowLabelsChange}
                            sensorOptions={sensorOptions}
                            selectedSensor={selectedSensor}
                            selectedYearRange={selectedYearRange}
                            setSelectedYearRange={setSelectedYearRange}
                            handleSensorChange={handleSensorChange}
                            handlePopoverOpen={handlePopoverOpen}
                            handlePopoverClose={handlePopoverClose}
                            anchorEl={anchorEl}
                            companyOptions={companyOptions}
                            selectedCompany={isSuperOrPowerAdmin ? selectedCompany : null}
                            handleCompanyChange={isSuperOrPowerAdmin ? handleCompanyChange : null}
                            isSuperAdmin={isSuperOrPowerAdmin}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Monthly;
