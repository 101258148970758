import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { CUSTOM_COLORS } from '../components/gradientDefs';

const OverviewChart = ({ selectedMac, error, overviewData, overviewLoading, tabValue }) => {
    const theme = useTheme();
    const [activeLegends, setActiveLegends] = useState([]);
    const themeMode = useSelector(({ global }) => global.mode);

    if (overviewLoading) {
        return <Loading />;
    }

    if (error || error?.status || !overviewData || Object.keys(overviewData).length === 0) {
        return <NoDataAvailableMessage />;
    }

    const processData = (data) => {
        if (!data || !data[selectedMac]) return [];
        const filteredData = data[selectedMac];
        const months = Object.entries(filteredData).flatMap(([year, yearData]) =>
            Object.entries(yearData).map(([month, counts]) => {
                const date = new Date(`${year}-${month}-01`);
                const formattedMonth = date.toLocaleString('default', { month: 'short' }).toUpperCase();
                const shortYear = year.slice(-2);
                return {
                    month: `${formattedMonth}-${shortYear}`,
                    ...counts,
                };
            })
        );
        return months;
    };

    const processedData = processData(overviewData);

    if (error || !processedData.length) {
        return <NoDataAvailableMessage />;
    }

    const lineIds = new Set();
    processedData.forEach((entry) => {
        Object.keys(entry).forEach((key) => {
            if (key !== 'month') {
                lineIds.add(key);
            }
        });
    });

    const lineData = Array.from(lineIds).map((id) => ({
        id,
        data: processedData.map((entry) => ({ x: entry.month, y: entry[id] || 0 })),
    }));

    const handleLegendClick = (legendItem) => {
        setActiveLegends((prevActiveLegends) =>
            prevActiveLegends.includes(legendItem.dataKey)
                ? prevActiveLegends.filter((item) => item !== legendItem.dataKey)
                : [...prevActiveLegends, legendItem.dataKey]
        );
    };

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    // Create a map to track hidden lines
    const hiddenLines = activeLegends.reduce((acc, key) => {
        acc[key] = true;
        return acc;
    }, {});

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={processedData}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 10,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis
                    dataKey="month"
                    angle={-40}
                    textAnchor="end"
                    interval={processedData.length > 12 ? 1 : 0}
                    tick={{ fill: textColor }}
                />
                <YAxis
                    tick={{ fill: textColor }}
                    width={30}
                />
                <Tooltip
                    contentStyle={{
                        backgroundColor: theme.palette.background.default,
                        border: 'none',
                        borderRadius: '8px',
                        color: theme.palette.text.primary,
                    }}
                />
                <Legend
                    align="center"
                    onClick={handleLegendClick}
                    wrapperStyle={{
                        paddingTop: 50,
                    }}
                    formatter={(value) => (
                        <span
                            style={{
                                color: hiddenLines[value] ? theme.palette.text.disabled : theme.palette.text.primary,
                                cursor: 'pointer',
                            }}
                        >
                            {value}
                        </span>
                    )}
                />
                {lineData.map((line, index) => (
                    <Line
                        key={line.id}
                        type="monotone"
                        dataKey={line.id}
                        strokeWidth={2}
                        dot={true}
                        name={line.id}
                        hide={hiddenLines[line.id]}
                        stroke={
                            index < CUSTOM_COLORS.length
                                ? CUSTOM_COLORS[index]
                                : undefined
                        }
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default OverviewChart;