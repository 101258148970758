import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Box, Drawer, IconButton, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import FlexBetween from './FlexBetween';
import logoDark from "../assets/logo-dark.png";
import logoLight from "../assets/logo-light.png";
import navItems from '../constants/navItems';

const Sidebar = ({ drawerWidth, isSidebarOpen, setIsSidebarOpen, isNonMobile, handleSidebarClose }) => {
    const { pathname } = useLocation();
    const [active, setActive] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();
    const role = useSelector(state => state.global.role);
    const themeMode = useSelector(state => state.global.mode);
    const userPermissions = useSelector(state => state.global.permissions);

    useEffect(() => {
        // Normalize pathname to handle routes with hyphens
        setActive(pathname.replace(/^\//, '').replace(/-/g, ' '));
    }, [pathname]);

    // Filter navItems based on role and permissions
    const filteredNavItems = navItems.filter(({ role: itemRole, permissions }) => {
        const shouldDisplayItem = itemRole.includes(role);
        const hasPermission = !permissions || permissions.every(permission => userPermissions.includes(permission));
        return shouldDisplayItem && hasPermission;
    });

    return (
        <Box component="nav">
            {isSidebarOpen && (
                <Drawer
                    variant="persistent"
                    anchor="left"
                    open={isSidebarOpen}
                    sx={{
                        width: drawerWidth,
                        "& .MuiDrawer-paper": {
                            backgroundColor: theme.palette.background.paper,
                            boxSizing: "border-box",
                            borderWidth: isNonMobile ? 0 : "2px",
                            width: drawerWidth,
                            overflow: 'hidden',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            boxShadow: '8px 8px 20px rgba(0, 0, 0, 0.1)',
                        },
                    }}
                >
                    <Box sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Box
                            sx={{
                                position: 'sticky',
                                top: 0,
                                backgroundColor: theme.palette.background.paper,
                                zIndex: 1,
                                pt: '1rem',
                            }}
                        >
                            <FlexBetween alignItems="center">
                                <Box sx={{ margin: 'auto' }}>
                                    <img src={themeMode === 'light' ? logoLight : logoDark} alt="JTrack" height="60px" />
                                    {!isNonMobile && (
                                        <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                                            <ChevronLeft />
                                        </IconButton>
                                    )}
                                </Box>
                            </FlexBetween>
                        </Box>

                        <Box sx={{ flex: 1, overflowY: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}>
                            <List sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                {filteredNavItems.map(({ text, icon }) => {
                                    const lcText = text.toLowerCase().replace(/ /g, '-');
                                    const isActive = active === text.toLowerCase();

                                    if (icon === null) {
                                        return (
                                            <ListItem key={text} disablePadding>
                                                <ListItemText disableTypography>
                                                    <Typography
                                                        variant="h5"
                                                        sx={{
                                                            paddingLeft: '2.5rem',
                                                            paddingTop: '1rem',
                                                            paddingBottom: '0.25rem',
                                                            fontWeight: 'bold',
                                                            color: theme.palette.text.white,
                                                        }}
                                                    >
                                                        {text}
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        );
                                    } else {
                                        return (
                                            <ListItem key={text} disablePadding sx={{ flex: 1 }}>
                                                <ListItemText disableTypography>
                                                    <Typography
                                                        component="div"
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            height: '100%',
                                                            background: isActive
                                                                ? `#5D5FEF`
                                                                : "transparent",
                                                            color: isActive ? 'white' : '#737791',
                                                            padding: '1rem 2.5rem',
                                                            margin: '0rem 1rem',
                                                            borderRadius: '15px',
                                                            '&:hover': {
                                                                backgroundColor: isActive ? '#5D5FEF' : theme.palette.action.hover,
                                                            },
                                                        }}
                                                        onClick={() => {
                                                            navigate(`/${lcText}`);
                                                            setActive(text.toLowerCase());
                                                            if (!isNonMobile) {
                                                                handleSidebarClose();
                                                            }
                                                        }}
                                                    >
                                                        {icon}
                                                        <span style={{ flex: 1, marginLeft: '1rem', marginRight: '2rem', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</span>
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        );
                                    }
                                })}
                            </List>
                        </Box>
                    </Box>
                </Drawer>
            )}
        </Box>
    );
};

export default Sidebar;