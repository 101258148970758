import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../redux/slices/globalSlice';

const INACTIVITY_TIMEOUT = 60 * 60 * 1000;

const PrivateRoute = () => {
    const isLoggedIn = useSelector((state) => state.global.isLoggedIn);
    const dispatch = useDispatch();
    const [lastActivity, setLastActivity] = useState(Date.now());

    const resetInactivityTimer = () => {
        setLastActivity(Date.now())
    };

    useEffect(() => {
        const activityEvents = ['keydown', 'click'];
        activityEvents.forEach((event) => {
            window.addEventListener(event, resetInactivityTimer);
        });

        return () => {
            activityEvents.forEach((event) => {
                window.removeEventListener(event, resetInactivityTimer);
            });
        };
    }, []);

    useEffect(() => {
        const inactivityTimer = setInterval(() => {
            if (Date.now() - lastActivity >= INACTIVITY_TIMEOUT) {
                dispatch(logout());
                document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            }
        }, 1000);

        return () => clearInterval(inactivityTimer);
    }, [lastActivity, dispatch]);

    return isLoggedIn ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
